import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainPanel from "@components-inbox/MainPanel/MainPanel.component";
import ConversationPanel from "@components-inbox/ConversationPanel/ConversationPanel";
import SummaryPanel from "@components-inbox/SummaryPanel/Summary.component";
import { loadSockets } from "@store-inbox/action";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { SideIcon } from "../styled";
import { Tooltip } from "antd";
import { payloadSelector } from "@store-inbox/MainPanel/selector";
import { useLocalStorage } from "@src/hooks/useLocalStorage";
import AppMenu from "../../../Menus/Menu";
import { Layout as Layouts } from "antd";
import QuickReply from "../QuickReplyPanel/QuickReply";
import { derivedConnectionsSelector } from "../MainPanel/utils";
import ReactHtmlParser from "react-html-parser";
import { currentActiveConversationSelector } from "@store-inbox/ConversationPanel/selector";

const Layout = () => {
  const dispatch = useDispatch();
  const mainPayload = useSelector(payloadSelector);
  const [payload, setPayload] = useState(mainPayload);
  const customerId = useSelector(currentActiveConversationSelector);
  const [openPanel, setOpenPanel] = useState(false);
  const [activeKey, setActiveKey] = useLocalStorage(
    "ticket_status",
    "assigned"
  );
  const [defaultKey, setDefaultKey] = useState(null);
  const [replyPanel, setReplyPanel] = useState(false);
  const [input, setInput] = useState("");
  const [newChat, setNewChat] = useState(false);
  const [phone, setPhone] = useState("");
  const connections = useSelector(derivedConnectionsSelector);
  const [notification, setNotification] = useState(null);
  const [background, setBackground] = useState("#31B7F6");
  const [bottomNotification, setBottomNotification] = useState(null);
  const [template, setTemplate] = useState(false);
  const [selectedArray, setSelectedArray] = useState([]);

  const { Sider } = Layouts;

  useEffect(() => {
    setTimeout(() => {
      dispatch(loadSockets());
    }, 1000);
  }, [dispatch]);

  const handleOpen = () => {
    setOpenPanel(!openPanel);
  };

  useEffect(() => {
    let regex =
      /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;
    if (
      connections &&
      connections.dashboard_notification &&
      connections.dashboard_notification.message !== null
    ) {
      setNotification(
        connections.dashboard_notification.message.replace(
          regex,
          function (url) {
            return (
              '<a href="' +
              url +
              '" style="text-decoration: underline"}}>' +
              url +
              "</a>"
            );
          }
        )
      );
      if (connections.dashboard_notification.level == "info") {
        setBackground("#31B7F6");
      } else if (connections.dashboard_notification.level == "warning") {
        setBackground("#FFAA33");
      } else if (connections.dashboard_notification.level == "danger") {
        setBackground("#EB463D");
      }
    } else {
      setNotification(null);
    }

    if (
      connections &&
      !connections.logisy_enabled &&
      connections.disable_reason &&
      connections.disable_reason !== null
    ) {
      setBottomNotification(
        connections.disable_reason.replace(regex, function (url) {
          return (
            '<a href="' +
            url +
            '" style="text-decoration: underline"}}>' +
            url +
            "</a>"
          );
        })
      );
    } else {
      setBottomNotification(null);
    }
  }, [connections]);

  useEffect(() => {
    if (customerId && customerId !== null && customerId !== "") {
      setOpenPanel(true);
    } else {
      setOpenPanel(false);
    }
  }, [customerId]);
  return (
    <Fragment>
      {notification && notification !== null && (
        <div
          style={{
            background: background,
            width: "100%",
            color: "#000",
            textAlign: "center",
            padding: "0.5rem",
          }}
        >
          {ReactHtmlParser(notification)}{" "}
          {connections.dashboard_notification.redirect_link !== null &&
            connections.dashboard_notification.redirect_link !== "" && (
              <a
                href={connections.dashboard_notification.redirect_link}
                style={{ color: "inherit", textDecoration: "underline" }}
                target="_blank"
              >
                {" "}
                Click here
              </a>
            )}{" "}
        </div>
      )}
      <div style={{ display: "flex" }}>
        <Sider className="sider" collapsed={true} theme="light">
          <div className="top-aside">
            <AppMenu />
          </div>
        </Sider>
        <MainPanel
          template={template}
          setTemplate={setTemplate}
          selectedArray={selectedArray}
          setSelectedArray={setSelectedArray}
          newChat={newChat}
          setNewChat={setNewChat}
          phone={phone}
          setPhone={setPhone}
          payload={payload}
          activeKey={activeKey}
          setActiveKey={setActiveKey}
          setPayload={setPayload}
        />
        <ConversationPanel
          template={template}
          setTemplate={setTemplate}
          selectedArray={selectedArray}
          setSelectedArray={setSelectedArray}
          newChat={newChat}
          setNewChat={setNewChat}
          phone={phone}
          setPhone={setPhone}
          input={input}
          setInput={setInput}
          replyPanel={replyPanel}
          setReplyPanel={setReplyPanel}
          setDefaultKey={setDefaultKey}
          activeKey={activeKey}
          setActiveKey={setActiveKey}
          payload={payload}
          setPayload={setPayload}
          openpanel={openPanel}
          setOpenPanel={setOpenPanel}
        />
        <QuickReply
          input={input}
          setInput={setInput}
          replyPanel={replyPanel}
          setReplyPanel={setReplyPanel}
          openpanel={openPanel}
        />
        <div style={{ position: "relative" }}>
          <Tooltip title="More details" placement="right">
            <SideIcon
              onClick={handleOpen}
              style={openPanel ? { height: "27px" } : { height: "100px" }}
            >
              {openPanel ? <CaretRightOutlined /> : <CaretLeftOutlined />}
            </SideIcon>
          </Tooltip>
        </div>
        {openPanel && <SummaryPanel defaultKey={defaultKey} />}
      </div>
      {bottomNotification && bottomNotification !== null && (
        <div
          style={{
            background: "#EB463D",
            zIndex: "99",
            width: "100%",
            color: "#fff",
            textAlign: "center",
            padding: "0.5rem",
            bottom: "0px",
            position: "fixed",
          }}
        >
          {bottomNotification}
        </div>
      )}
    </Fragment>
  );
};

export default React.memo(Layout);
