import styled from "styled-components";

export const EmailWrapper = styled.div`
  position: absolute;
  right: 100px;
  bottom: 0px;
  height: 70%;
  max-height: 650px;
  max-width: 600px;
  width: 100%;
  z-index: 99;
  background: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.7);

  div {
    margin: 0px 10px;
  }
  .slate-editor {
    margin: 0px;
  }
  .editor-container {
    margin-top: 10px;
    overflow-y: scroll;
    padding-top: 10px;
    line-height: 1.5;
  }
`;

export const EmailHeading = styled.div`
  background: var(--color-primary);
  display: flex;
  justify-content: space-between;
  color: #fff;
  padding: 10px;
  margin: 0px !important;

  svg {
    cursor: pointer;
  }
`;
