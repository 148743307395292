import { put, call, takeLatest, select, take } from "redux-saga/effects";
import * as types from "./types";
import * as api from "@utils/api";
import * as actions from "./action";
import { resolveErrorCodes } from "@utils/errorCodes";
import * as selectors from "./selector";
import { toast } from "react-toastify";

toast.configure();

function* getAgentsSaga(action) {
  try {
    const agentsData = yield call(api.getAgents);
    yield put({
      type: types.GET_AGENTS_SUCCESS,
      payload: agentsData.data.data,
    });
  } catch (err) {
    // resolveErrorCodes(err);
    toast.error(
      "Internal Server error. Please refresh the page and try again",
      {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
      }
    );
    if (err.response && err.response.data) {
      api.errorLogsApi(err.response.data);
    } else {
      api.errorLogsApi(err);
    }
    yield put({
      type: types.GET_AGENTS_ERROR,
      payload: err,
    });
  }
}

function* getOnlineStatus() {
  try {
    const onlineStatus = yield call(api.getOnlineStatus);
    yield put({
      type: types.GET_STATUS_SUCCESS,
      payload: onlineStatus.data.data,
    });
  } catch (err) {
    yield put({
      type: types.GET_STATUS_ERROR,
      payload: err,
    });
  }
}

function* setOnlineStatusSaga(action) {
  const { payload } = action;
  try {
    const onlineStatusData = yield call(api.setOnlineStatus, payload);
    yield put({
      type: types.SET_ONLINE_STATUS_SUCCESS,
      payload: onlineStatusData.data.data,
    });
  } catch (err) {
    // resolveErrorCodes(err);
    if (err.response && err.response.data) {
      api.errorLogsApi(err.response.data);
    } else {
      api.errorLogsApi(err);
    }
    toast.error(
      "Internal Server error. Please refresh the page and try again",
      {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
      }
    );
    yield put({
      type: types.SET_ONLINE_STATUS_ERROR,
      payload: err,
    });
  }
}

function* getConnectionsSaga(action) {
  try {
    const connectionsData = yield call(api.getConnections);
    yield put({
      type: types.GET_CONNECTIONS_SUCCESS,
      payload: connectionsData.data.data,
    });
  } catch (err) {
    // resolveErrorCodes(err);
    if (err.response && err.response.data) {
      api.errorLogsApi(err.response.data);
    } else {
      api.errorLogsApi(err);
    }
    toast.error(
      "Internal Server error. Please refresh the page and try again",
      {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
      }
    );
    yield put({
      type: types.GET_CONNECTIONS_ERROR,
      payload: err,
    });
  }
}

function* getTemplates(action) {
  try {
    const templates = yield call(api.getTemplates);
    yield put({
      type: types.GET_TEMPLATES_SUCCESS,
      payload: templates.data.data.results,
    });
  } catch (err) {
    // resolveErrorCodes(err);
    if (err.response && err.response.data) {
      api.errorLogsApi(err.response.data);
    } else {
      api.errorLogsApi(err);
    }
    toast.error(
      "Internal Server error. Please refresh the page and try again",
      {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
      }
    );
    yield put({
      type: types.GET_TEMPLATES_ERROR,
      payload: err,
    });
  }
}

function* getConversations(action) {
  try {
    const ticketsData = yield call(api.getTicketsListApi, {
      payload: action.payload,
    });
    yield put(actions.receiveConversations(ticketsData.data));
  } catch (err) {
    console.log(err);
  }
}

function* getFilteredConversations() {
  try {
    const payload = yield select(selectors.payloadSelector);
    const ticketsData = yield call(api.getTicketsListApi, { payload: payload });
    yield put(actions.receiveConversations(ticketsData.data));
  } catch (err) {
    console.log(err);
  }
}

function* getInitiateConversation(action) {
  try {
    const payload = yield call(api.initiateConversation, {
      payload: action.payload,
    });
    yield put(actions.conversationInitiated({ data: payload.data }));
  } catch (err) {
    console.log(err);
  }
}

function* getMsgsCount(action) {
  try {
    if (action.payload.refresh) {
      yield put(actions.refreshStatus({ refreshStatus: "refreshing" }));
    }
    const payload = yield call(api.msgsCounter, action.payload);
    yield put({
      type: types.MSGS_COUNT_SUCCESS,
      payload,
    });
  } catch (err) {
    console.log(err);
  }
}

function* getAllTags(action) {
  try {
    const tagsData = yield call(api.getTagsApi);
    yield put({
      type: types.GET_POPULAR_TAGS_SUCCESS,
      payload: tagsData.data.data,
    });
  } catch (err) {
    // resolveErrorCodes(err);
    if (err.response && err.response.data) {
      api.errorLogsApi(err.response.data);
    } else {
      api.errorLogsApi(err);
    }
    toast.error(
      "Internal Server error. Please refresh the page and try again",
      {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
      }
    );
    yield put({
      type: types.GET_POPULAR_TAGS_ERROR,
      payload: err,
    });
  }
}

function* getNewTicket(action) {
  try {
    const data = yield call(api.createNewTicketApi, action.payload);
    yield put({
      type: types.CREATE_NEW_TICKET_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: types.CREATE_NEW_TICKET_ERROR,
      payload: err.response.data.error.message,
    });
  }
}

function* getLabelFieldsSaga(action) {
  try {
    const fieldsData = yield call(api.getLabelFields);
    yield put({
      type: types.GET_FIELDS_SUCCESS,
      payload: fieldsData.data.data,
    });
  } catch (err) {
    // resolveErrorCodes(err);
    if (err.response && err.response.data) {
      api.errorLogsApi(err.response.data);
    } else {
      api.errorLogsApi(err);
    }
    toast.error(
      "Internal Server error. Please refresh the page and try again",
      {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
      }
    );
    yield put({
      type: types.GET_FIELDS_ERROR,
      payload: err,
    });
  }
}

function* getMailInitiation(action) {
  const { payload } = action;
  try {
    const mailResponse = yield call(api.initiateMailApi, payload);
    yield put({
      type: types.INITIATE_MAIL_SUCCESS,
      payload: mailResponse.data.data,
    });
  } catch (err) {
    if (err.response && err.response.data) {
      api.errorLogsApi(err.response.data);
      yield put({
        type: types.INITIATE_MAIL_FAIL,
        payload: err.response.data,
      });
    } else {
      api.errorLogsApi(err);
      yield put({
        type: types.INITIATE_MAIL_FAIL,
        payload: err,
      });
    }
  }
}

function* getEmailGroupsSaga(action) {
  try {
    const emailGroups = yield call(api.getEmailGroups);
    yield put({
      type: types.GET_EMAIL_GROUPS_SUCCESS,
      payload: emailGroups.data.data,
    });
  } catch (err) {
    // resolveErrorCodes(err);
    if (err.response && err.response.data) {
      api.errorLogsApi(err.response.data);
    } else {
      api.errorLogsApi(err);
    }
    toast.error(
      "Internal Server error. Please refresh the page and try again",
      {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
      }
    );
    yield put({
      type: types.GET_EMAIL_GROUPS_ERROR,
      payload: err,
    });
  }
}

function* watchGetAgents() {
  yield takeLatest([types.GET_AGENTS], getAgentsSaga);
}

function* watchGetConnections() {
  yield takeLatest([types.GET_CONNECTIONS], getConnectionsSaga);
}

function* watchTemplates() {
  yield takeLatest([types.GET_TEMPLATES], getTemplates);
}

function* watchConversations() {
  yield takeLatest([types.GET_CONVERSATIONS], getConversations);
}

function* watchFilters() {
  yield takeLatest(
    [types.SET_CONVERSATIONS_SEARCH_FILTERS],
    getFilteredConversations
  );
}

function* watchFiltersBG() {
  yield take(
    [types.SET_CONVERSATIONS_SEARCH_FILTERS_BG],
    getFilteredConversations
  );
}

function* watchTabFilters() {
  yield takeLatest([types.SET_TAB_FILTERS], getConversations);
}

function* watchPagination() {
  yield takeLatest([types.SET_CONVERSATIONS_PAGINATION], getConversations);
}

function* watchInitiateConversation() {
  yield takeLatest(types.INITIATE_CONVERSATION, getInitiateConversation);
}

function* watchMsgsCount() {
  yield takeLatest(types.GET_MSGS_COUNT, getMsgsCount);
}

function* watchGetOnlineStatus() {
  yield takeLatest(types.GET_STATUS, getOnlineStatus);
}

function* watchOnlineStatus() {
  yield takeLatest([types.SET_ONLINE_STATUS], setOnlineStatusSaga);
}

function* watchTags() {
  yield takeLatest(types.GET_POPULAR_TAGS, getAllTags);
}

function* watchNewTicket() {
  yield takeLatest(types.CREATE_NEW_TICKET, getNewTicket);
}

function* watchLabelFieldsSaga() {
  yield takeLatest(types.GET_FIELDS, getLabelFieldsSaga);
}

function* watchInitiateMail() {
  yield takeLatest(types.INITIATE_MAIL, getMailInitiation);
}

function* watchEmailGroupsSaga() {
  yield takeLatest(types.GET_EMAIL_GROUPS, getEmailGroupsSaga);
}

export default [
  watchGetAgents,
  watchGetConnections,
  watchTemplates,
  watchConversations,
  watchFilters,
  watchFiltersBG,
  watchTabFilters,
  watchPagination,
  watchInitiateConversation,
  watchMsgsCount,
  watchGetOnlineStatus,
  watchOnlineStatus,
  watchTags,
  watchNewTicket,
  watchLabelFieldsSaga,
  watchInitiateMail,
  watchEmailGroupsSaga,
];
