import React, { useEffect, useState } from "react";
import parse from "date-fns/parse";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, Select, DatePicker } from "antd";
import { setSearchFilters } from "@store-inbox/MainPanel/action";
import {
  FilterWrapper,
  StyledButtonWrapper,
  FilterHeading,
  FilterTitle,
  FilterElements,
} from "./styled";
import { openDrawer } from "@store-inbox/action";
import { ReactComponent as DownArrowIcon } from "@assets/icons/main/downarrow.svg";
import moment from "moment";
import { DownloadOutlined } from "@ant-design/icons";
import { getCSV } from "@utils/api";
import { toast } from "react-toastify";
import { derivedConnectionsSelector } from "@components-inbox/MainPanel/utils";
import { derivedAllAgentsDataSelector } from "@components-inbox/ConversationPanel/utils";
import { getMsgsCount } from "@store-inbox/MainPanel/action";

function FilterPanel(props) {
  const { payload, setPayload, allTags, text } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [priority, setPriority] = useState(
    payload.filters.priority_filter !== null
      ? payload.filters.priority_filter
      : "-Select-"
  );
  const [ticket, setTicket] = useState(payload.filters.ticket_status);
  const [readstatus, setReadStatus] = useState(
    payload.filters.unread !== null ? payload.filters.unread : "-Select-"
  );
  const [conversationInitiatedBy, setConversationInitiatedBy] = useState(null);
  const [date, setDate] = useState({
    selection: {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  });
  const { Option } = Select;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(new Date());
  const [tags, setTags] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const derivedAgents = useSelector(derivedAllAgentsDataSelector);
  const [allAgents, setAllAgents] = useState([]);
  const settings = useSelector(derivedConnectionsSelector);

  toast.configure();

  const [ticketExpiry, setTicketExpiry] = useState(
    payload.filters.sort_by !== null ? payload.filters.sort_by : "-Select-"
  );

  let count = 0;
  if (startDate !== null) {
    count += 1;
  }
  if (priority !== null) {
    count += 1;
  }
  if (readstatus !== null) {
    count += 1;
  }
  if (ticket !== null) {
    count += 1;
  }
  if (tags !== null && tags.length > 0) {
    count += 1;
  }

  useEffect(() => {
    if (derivedAgents) {
      let temp = [];
      for (let i = 0; i < derivedAgents.length; i++) {
        if (derivedAgents[i].id !== undefined) {
          temp.push(
            <Option key={derivedAgents[i].id}>
              {derivedAgents[i].first_name} {derivedAgents[i].last_name}
            </Option>
          );
        }
      }
      setAllAgents(temp);
      if (payload.filters) {
        setSelectedAgents(
          payload.filters.agent_ids && payload.filters.agent_ids !== null
            ? payload.filters.agent_ids
            : [String(settings.current_user_id)]
        );
      }
    }
  }, [settings, derivedAgents, payload]);

  useEffect(() => {
    if (payload.filters) {
      setPriority(payload.filters.priority_filter);
      setTicket(payload.filters.ticket_status);
      setConversationInitiatedBy(payload.filters.initiated_by);
      setReadStatus(payload.filters.unread);
      setTags(
        payload.filters.tag_filter !== null ? payload.filters.tag_filter : []
      );
      if (payload.filters.date_filter_start) {
        setDate({
          selection: {
            startDate: parse(
              payload.filters.date_filter_start,
              "yyyy-MM-dd",
              new Date()
            ),
            endDate: parse(
              payload.filters.date_filter_end,
              "yyyy-MM-dd",
              new Date()
            ),
            key: "selection",
          },
        });
      }
      setTicketExpiry(payload.filters.sort_by);
    }
  }, [payload]);

  const onFilter = () => {
    const filters = {
      date_filter_start: startDate
        ? moment(startDate).format("yyyy-MM-DD")
        : null,
      date_filter_end: endDate ? moment(endDate).format("yyyy-MM-DD") : null,
      initiated_by: conversationInitiatedBy,
      priority_filter: priority,
      unread: readstatus,
      ticket_status: ticket,
      tag_filter: tags,
      search: text !== "" ? text : null,
      agent_ids:
        selectedAgents && selectedAgents !== null ? selectedAgents : null,
      sort_by: ticketExpiry,
    };
    dispatch(
      setSearchFilters({
        ...payload,
        filters: {
          ...payload.filters,
          ...filters,
        },
        fetch_since: null,
        page_size: 25,
      })
    );
    // setActiveKey("");
    setPayload({
      ...payload,
      filters: {
        ...payload.filters,
        ...filters,
      },
      fetch_since: null,
      page_size: 25,
    });
    dispatch(getMsgsCount({ selectedAgents, refresh: false }));
    history.push(`/inbox/tickets`);
    dispatch(openDrawer(false));
  };

  const exportCSV = () => {
    const filters = {
      date_filter_start: startDate
        ? moment(startDate).format("yyyy-MM-DD")
        : null,
      date_filter_end: endDate ? moment(endDate).format("yyyy-MM-DD") : null,
      initiated_by: conversationInitiatedBy,
      priority_filter: priority,
      unread: readstatus,
      ticket_status: ticket,
      tag_filter: tags,
      search: text !== "" ? text : null,
      agent_ids:
        selectedAgents && selectedAgents !== null ? selectedAgents : null,
      sort_by: ticketExpiry,
    };

    getCSV(filters)
      .then((resp) => {
        if (resp.data.success) {
          toast.success("Report will be emailed shortly.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        } else {
          toast.error("Error! Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        }
      })
      .catch((err) => {
        toast.error("Error! Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      });
    dispatch(openDrawer(false));
  };

  const handleDeleteTag = (e) => {
    const index = tags.indexOf(e);
    const tempTags = [...tags];
    tempTags.splice(index, 1);
    setTags(tempTags);
  };

  const handleDeleteAgent = (e) => {
    const index = selectedAgents.indexOf(e);
    const tempAgents = [...selectedAgents];
    tempAgents.splice(index, 1);
    setSelectedAgents(tempAgents);
  };

  const close = () => {
    dispatch(openDrawer(false));
  };

  function disabledDate(current) {
    return current && current > moment().endOf("day");
  }

  return (
    <FilterWrapper>
      <FilterHeading>
        <p style={{ margin: "0" }}>Filters</p>
        <p style={{ margin: "0" }} className="close" onClick={() => close()}>
          x
        </p>
      </FilterHeading>
      <Divider style={{ margin: "6px 0 0" }} />
      <FilterElements>
        <div style={{ width: "100%" }}>
          <FilterTitle>Sort tickets by</FilterTitle>
          <Select
            size="large"
            suffixIcon={
              <DownArrowIcon style={{ marginTop: "-5px", stroke: "#474747" }} />
            }
            defaultValue={ticketExpiry}
            onChange={(value) => setTicketExpiry(value)}
            style={{ fontSize: "20px", width: "100%" }}
          >
            <Option key="expiring_soon"> Expiring soon </Option>
            <Option key="expired"> Expired </Option>
            <Option key="received_at"> Received at </Option>
            <Option key="replied_at"> Replied at </Option>
          </Select>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
          }}
        >
          <div style={{ width: "85%" }}>
            <FilterTitle>Read Status</FilterTitle>
            <Select
              size="large"
              suffixIcon={
                <DownArrowIcon
                  style={{ marginTop: "-5px", stroke: "#474747" }}
                />
              }
              defaultValue={readstatus}
              onChange={(value) => {
                if (value == "null") {
                  setReadStatus(null);
                } else {
                  setReadStatus(value);
                }
              }}
              style={{ fontSize: "20px", width: "100%" }}
            >
              <Option key={null}> All </Option>
              <Option key="false"> Read </Option>
              <Option key="true"> Unread </Option>
            </Select>
          </div>
          <div style={{ width: "100%", marginLeft: "30px" }}>
            <FilterTitle style={{ marginBottom: "5px" }}>Priority</FilterTitle>
            <Select
              size="large"
              suffixIcon={
                <DownArrowIcon
                  style={{ marginTop: "-5px", stroke: "#474747" }}
                />
              }
              defaultValue={priority}
              onChange={(value) => {
                if (value == "null") {
                  setPriority(null);
                } else {
                  setPriority(value);
                }
              }}
              style={{ fontSize: "20px", width: "100%" }}
            >
              <Option key={null}> All </Option>
              <Option key={1}> High </Option>
              <Option key={2}> Medium </Option>
              <Option key={3}> Low </Option>
            </Select>
          </div>
        </div>
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ width: "100%" }}>
            <FilterTitle>Date Range (From)</FilterTitle>
            <DatePicker
              size="large"
              value={startDate !== null && moment(startDate)}
              disabledDate={disabledDate}
              style={{ width: "85%" }}
              onChange={(date) => setStartDate(date)}
            />
          </div>
          <div style={{ width: "100%" }}>
            <FilterTitle>Date Range (To)</FilterTitle>
            <DatePicker
              defaultValue={moment()}
              value={endDate !== null && moment(endDate)}
              format={"yyyy-MM-DD"}
              size="large"
              disabledDate={disabledDate}
              style={{ width: "100%" }}
              onChange={(date) => setEndDate(date)}
            />
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <FilterTitle>Tags</FilterTitle>
          <Select
            allowClear={true}
            mode="multiple"
            size="large"
            style={{
              width: "100%",
              fontSize: "16px",
            }}
            placeholder="Select Tags"
            value={tags !== null && tags.length > 0 ? tags.map((a) => a) : []}
            placement="bottomLeft"
            onSelect={(e) => setTags([...tags, e])}
            onDeselect={(e) => handleDeleteTag(e)}
          >
            {allTags}
          </Select>
        </div>
        <div style={{ width: "100%" }}>
          <FilterTitle>View tickets of</FilterTitle>
          <Select
            allowClear={true}
            mode="multiple"
            size="large"
            style={{
              width: "100%",
              fontSize: "16px",
            }}
            placeholder="Select Agents"
            value={
              selectedAgents &&
              selectedAgents !== null &&
              selectedAgents.length > 0
                ? selectedAgents.map((a) => a)
                : []
            }
            placement="bottomLeft"
            onSelect={(e) =>
              e !== undefined && setSelectedAgents([...selectedAgents, e])
            }
            onDeselect={(e) => e !== undefined && handleDeleteAgent(e)}
          >
            {allAgents}
          </Select>
        </div>
        {/* <label style={{ fontSize: "16px", fontWeight: "400" }}>
          <input
            type="checkbox"
            style={{
              marginRight: "10px",
              marginTop: "30px",
              width: "15px",
              height: "15px",
            }}
            checked={ticket === "assigned_to_bot" ? true : false}
            onChange={(e) => {
              if (e.target.checked) {
                setTicket("assigned_to_bot");
              } else {
                setTicket(null);
              }
            }}
          />
          Show Uninitiated Tickets (Chatbot)
        </label> */}
        <Button
          type="secondary"
          icon={<DownloadOutlined />}
          style={{
            marginBottom: "0px",
            marginTop: "10px",
            border: "1px solid var(--color-primary)",
          }}
          onClick={exportCSV}
        >
          Apply Filters & Export
        </Button>
        <StyledButtonWrapper>
          {(startDate || priority || readstatus || ticket) !== null && (
            <p>
              {count} filter{count > 1 && <span>s</span>} applied
            </p>
          )}
          <Button
            className="filter-button"
            variant="contained"
            onClick={onFilter}
          >
            Apply Filters
          </Button>
        </StyledButtonWrapper>
      </FilterElements>
    </FilterWrapper>
  );
}

export default FilterPanel;
