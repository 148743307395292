import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import { StyledOnlineStatusWrapper } from "./styled";
import {
  setOnlineStatus,
  getMsgsCount,
  getConversations,
} from "@store-inbox/MainPanel/action";
import { logout } from "@utils/api";
import PragmaLogo from "@assets/pragma-light.svg";
import { CheckOutlined, ReloadOutlined } from "@ant-design/icons";
import { payloadSelector, refreshStatusSelector } from "@store-inbox/MainPanel/selector";
import { currentActiveConversationSelector } from "@store-inbox/ConversationPanel/selector";
import { setActiveConversation } from "@store-inbox/ConversationPanel/action";
import { wsStatus } from "@store-inbox/selector";
import { reloadWS } from "@store-inbox/action";
// import { onlineStatusDataSelector } from "@store-inbox/MainPanel/selector";

function Online(props) {
  const dispatch = useDispatch();
  const defaultstatus = localStorage.getItem("agent_status", "offline");
  const [refreshText, setRefreshText] = useState("Refresh");
  const [rotating, setRotating] = useState(false);
  const {payload} = props;
  const id = useSelector(currentActiveConversationSelector);
  const status = useSelector(wsStatus);
  const [refreshStatus, setRefreshStatus] = useState(false);
  const refreshSelector = useSelector(refreshStatusSelector);

  // const defaultstatus = useSelector(onlineStatusDataSelector);

  const onChange = (e) => {
    dispatch(setOnlineStatus(e));
    localStorage.setItem("agent_status", e);
    if (e == "logout") {
      logout();
    }
  };
  const { Option } = Select;

  const Online = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            height: "6px",
            width: "6px",
            background: "#16AC37",
            borderRadius: "50%",
            marginRight: "10px",
          }}
        />
        <span style={{ color: "#333333" }}>Online</span>
      </div>
    );
  };

  const Offline = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            height: "6px",
            width: "6px",
            background: "#DB2525",
            borderRadius: "50%",
            marginRight: "10px",
          }}
        />
        <span style={{ color: "#333333" }}>Offline</span>
      </div>
    );
  };

  const Break = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            height: "6px",
            width: "6px",
            background: "#ffc700",
            borderRadius: "50%",
            marginRight: "10px",
          }}
        />
        <span style={{ color: "#333333" }}>Break</span>
      </div>
    );
  };

  const refreshList = () => {
    dispatch(getMsgsCount({ selectedAgents: payload.agent_ids, refresh: true }));
    setRefreshText("Refreshing");
    setRotating(true);
    dispatch(getConversations(payload));
    if (id && id !== null && id !== "") {
      dispatch(setActiveConversation(id));
    }
    if (!status) {
      dispatch(reloadWS());
    }
  };

  useEffect(() => {
    if(refreshSelector == "refreshed"){
      setRefreshText("Refreshed");
      setRefreshStatus(true);
      setRotating(false);
      
      setTimeout(() => {
        setRefreshText("Refresh");
        setRefreshStatus(false);
      }, 1000);
    }
  }, [refreshSelector]);

  return (
    <StyledOnlineStatusWrapper>
      <img
        src={PragmaLogo}
        alt="PRAGMA."
        style={{ margin: "40px 10px 20px", width: "150px" }}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          border: "1px solid #d9d9d9",
          marginTop: "20px",
          padding: "4px 16px",
          color: refreshStatus ? '#fff' : "#3F578D",
          fontWeight: "500",
          cursor: "pointer",
          background: refreshStatus ? '#16AC37' : '#fff'
        }}
        onClick={() => refreshList()}
      >
        {refreshStatus ? (
          <CheckOutlined style={{ color: "#fff", marginRight: "5px" }} />
        ) : (
          <div
            style={{
              transform: "scaleX(-1)",
              marginRight: "5px",
              color: "#3F578D",
            }}
          >
            <ReloadOutlined className={rotating ? "rotate-icon" : ""} />
          </div>
        )}
        {refreshText}
      </div>
      <Select
        placeholder={
          defaultstatus == "break" ? (
            <Break />
          ) : defaultstatus == "online" ? (
            <Online />
          ) : (
            <Offline />
          )
        }
        size="default"
        style={{ width: 100, marginTop: "20px" }}
        onChange={onChange}
      >
        <Option key="online" value="online" style={{ marginTop: "5px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                height: "6px",
                width: "6px",
                background: "#16AC37",
                borderRadius: "50%",
                marginRight: "8px",
              }}
            />
            <span style={{ color: "#474747" }}>Online</span>
          </div>
        </Option>
        <Option key="offline" value="offline" style={{ marginTop: "5px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                height: "6px",
                width: "6px",
                background: "#DB2525",
                borderRadius: "50%",
                marginRight: "8px",
              }}
            />
            <span style={{ color: "#474747" }}>Offline</span>
          </div>
        </Option>
        <Option key="break" value="break" style={{ marginTop: "5px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                height: "6px",
                width: "6px",
                background: "#ffc700",
                borderRadius: "50%",
                marginRight: "8px",
              }}
            />
            <span style={{ color: "#474747" }}>Break</span>
          </div>
        </Option>
        <Option key="logout" value="logout" style={{ marginTop: "5px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                height: "6px",
                width: "6px",
                background: "#C4C4C4",
                borderRadius: "50%",
                marginRight: "8px",
              }}
            />
            <span style={{ color: "#474747" }}>Logout</span>
          </div>
        </Option>
      </Select>
    </StyledOnlineStatusWrapper>
  );
}

export default Online;
