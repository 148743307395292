import React, { useEffect } from "react";
import TabPanel from "./TabPanel/TabPanel.component";
import { useDispatch, useSelector } from "react-redux";
import { getConversations } from "@store-inbox/MainPanel/action";
import { tabsDataSelector, derivedConnectionsSelector } from "./utils";
import { audioSelector } from "@store-inbox/ConversationPanel/selector";
import sound from "@assets/facebookpop.mp3";
import { wsStatus } from "@store-inbox/selector";
import { useParams } from "react-router-dom";
import { setActiveConversation } from "@store-inbox/ConversationPanel/action";
import { derivedAllTickets } from "../ConversationPanel/utils";

const MainPanel = (props) => {
  const tabsData = useSelector(tabsDataSelector);
  const dispatch = useDispatch();
  const {
    payload,
    setPayload,
    activeKey,
    setActiveKey,
    newChat,
    setNewChat,
    phone,
    setPhone,
    template,
    setTemplate,
    selectedArray,
    setSelectedArray
  } = props;
  const connections = useSelector(derivedConnectionsSelector);
  const audioPlay = useSelector(audioSelector);
  const status = useSelector(wsStatus);
  const { id } = useParams();
  const allTickets = useSelector(derivedAllTickets);

  useEffect(() => {
    if (connections.inbox_audio_notifications_enabled && audioPlay !== null) {
      const song = new Audio(sound);
      song.play();
    }
  }, [audioPlay]);

  useEffect(() => {
    // if (status) {
      // dispatch(getConversations(payload));
      if (
        allTickets === undefined ||
        (allTickets === null && id !== undefined) ||
        id !== null
      ) {
        dispatch(setActiveConversation(id || ""));
      }
    // }
  }, []);

  // useEffect(() => {
  //   if(status){
  //     if(allTickets && allTickets!==null){
  //       if((tabsData===undefined || tabsData===null || tabsData.length ===0) && id && id!==null){
  //         const tempPayload =
  //           {
  //             ...payload,
  //             filters: {
  //                 ...payload.filters,
  //                 ticket_status: allTickets[allTickets.length-1].status || payload.filters.ticket_status
  //             },
  //             fetch_since: null,
  //             page_size: 25
  //           }
  //           setPayload(tempPayload);
  //           dispatch(getConversations(tempPayload));
  //           if(allTickets[allTickets.length-1].status !== "assigned_to_bot"){
  //             setActiveKey(allTickets[allTickets.length-1].status);
  //           }else{
  //             setActiveKey("");
  //           }
  //       }
  //     }
  //   }
  // }, [dispatch, status, id, allTickets]);

  return (
    <TabPanel
      newChat={newChat}
      phone={phone}
      setPhone={setPhone}
      setNewChat={setNewChat}
      activeKey={activeKey}
      setActiveKey={setActiveKey}
      tabsData={tabsData}
      payload={payload}
      setPayload={setPayload}
      template={template}
      setTemplate={setTemplate}
      selectedArray={selectedArray}
      setSelectedArray={setSelectedArray}
    />
  );
};

export default MainPanel;
