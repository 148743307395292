export const SCOPE = "main";
export const INIT = `${SCOPE}/INIT`;
export const GET_CONVERSATIONS = `${SCOPE}/GET_CONVERSATIONS`;
export const RECEIVE_CONVERSATIONS = `${SCOPE}/RECEIVE_CONVERSATIONS`;
export const SET_CONVERSATIONS_TABS = `${SCOPE}/SET_CONVERSATIONS_TABS`;
export const SET_CONVERSATIONS_ACTIVE_TAB = `${SCOPE}/SET_CONVERSATIONS_ACTIVE_TAB`;
export const SET_CONVERSATIONS_SEARCH_FILTERS = `${SCOPE}/SET_CONVERSATIONS_SEARCH_FILTERS`;
export const SET_CONVERSATIONS_SEARCH_FILTERS_BG = `${SCOPE}/SET_CONVERSATIONS_SEARCH_FILTERS_BG`;
export const SET_TAB_FILTERS = `${SCOPE}/SET_TAB_FILTERS`;
export const GET_AGENTS = `${SCOPE}/GET_AGENTS`;
export const GET_AGENTS_SUCCESS = `${SCOPE}/GET_AGENTS_SUCCESS`;
export const GET_AGENTS_ERROR = `${SCOPE}/GET_AGENTS_ERROR`;
export const GET_CONNECTIONS = `${SCOPE}/GET_CONNECTIONS`;
export const GET_CONNECTIONS_SUCCESS = `${SCOPE}/GET_CONNECTIONS_SUCCESS`;
export const GET_CONNECTIONS_ERROR = `${SCOPE}/GET_CONNECTIONS_ERROR`;
export const SET_CONVERSATIONS_PAGINATION = `${SCOPE}/SET_CONVERSATIONS_PAGINATION`;
export const TOKEN_EXPIRED = `${SCOPE}/TOKEN_EXPIRED`;
export const GET_TEMPLATES = `${SCOPE}/GET_TEMPLATES`;
export const GET_TEMPLATES_SUCCESS = `${SCOPE}/GET_TEMPLATES_SUCESS`;
export const GET_TEMPLATES_ERROR = `${SCOPE}/GET_TEMPLATES_ERROR`;
export const INITIATE_CONVERSATION = `${SCOPE}/INITIATE_CONVERSATION`;
export const CONVERSATION_INITIATED = `${SCOPE}/CONVERSATION_INITIATED`;
export const FALSE_INITIATED = `${SCOPE}/FALSE_INITIATED`;
export const MARK_AS_UNREAD = `${SCOPE}/MARK_AS_UNREAD`;
export const GET_MSGS_COUNT = `${SCOPE}/GET_MSGS_COUNT`;
export const MSGS_COUNT_SUCCESS = `${SCOPE}/MSGS_COUNT_SUCCESS`;
export const MSGS_COUNT_UPDATE = `${SCOPE}/MSGS_COUNT_UPDATE`;
export const UNASSIGNED_MSGS_COUNT_UPDATE = `${SCOPE}/UNASSIGNED_MSGS_COUNT_UPDATE`;
export const GET_STATUS = `${SCOPE}/GET_STATUS`;
export const GET_STATUS_SUCCESS = `${SCOPE}/GET_STATUS_SUCESS`;
export const GET_STATUS_ERROR = `${SCOPE}/GET_STATUS_ERROR`;
export const SET_ONLINE_STATUS = `${SCOPE}/SET_ONLINE_STATUS`;
export const SET_ONLINE_STATUS_SUCCESS = `${SCOPE}/SET_ONLINE_STATUS_SUCCESS`;
export const SET_ONLINE_STATUS_ERROR = `${SCOPE}/SET_ONLINE_STATUS_ERROR`;
export const GET_POPULAR_TAGS = `${SCOPE}/GET_POPULAR_TAGS`;
export const GET_POPULAR_TAGS_SUCCESS = `${SCOPE}/GET_POPULAR_TAGS_SUCCESS`;
export const GET_POPULAR_TAGS_ERROR = `${SCOPE}/GET_POPULAR_TAGS_ERROR`;
export const SET_POPULAR_TAGS = `${SCOPE}/SET_POPULAR_TAGS`;
export const REFRESH_STATUS = `${SCOPE}/REFRESH_STATUS`;
export const CREATE_NEW_TICKET = `${SCOPE}/CREATE_NEW_TICKET`;
export const CREATE_NEW_TICKET_SUCCESS = `${SCOPE}/CREATE_NEW_TICKET_SUCCESS`;
export const CREATE_NEW_TICKET_ERROR = `${SCOPE}/CREATE_NEW_TICKET_ERROR`;
export const GET_FIELDS = `${SCOPE}/GET_FIELDS`;
export const GET_FIELDS_SUCCESS = `${SCOPE}/GET_FIELDS_SUCCESS`;
export const GET_FIELDS_ERROR = `${SCOPE}/GET_FIELDS_ERROR`;
export const INITIATE_MAIL = `${SCOPE}/INITIATE_MAIL`;
export const INITIATE_MAIL_SUCCESS = `${SCOPE}/INITIATE_MAIL_SUCCESS`;
export const INITIATE_MAIL_FAIL = `${SCOPE}/INITIATE_MAIL_FAIL`;
export const GET_EMAIL_GROUPS = `${SCOPE}/GET_EMAIL_GROUPS`;
export const GET_EMAIL_GROUPS_SUCCESS = `${SCOPE}/GET_EMAIL_GROUPS_SUCCESS`;
export const GET_EMAIL_GROUPS_ERROR = `${SCOPE}/GET_EMAIL_GROUPS_ERROR`;
export const RESET_MAIL = `${SCOPE}/RESET_MAIL`;
