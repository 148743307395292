import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConversationList from "../ConversationList/ConversationList.component";
import { setTabFilters } from "@store-inbox/MainPanel/action";
import { Tabs } from "antd";
import { StyledSubPanel } from "../styled";
import { useHistory } from "react-router-dom";
import { setActiveConversation } from "@store-inbox/ConversationPanel/action";
import { msgsCount } from "@store-inbox/MainPanel/selector";

const { TabPane } = Tabs;

export default function SubTabPanel(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    tabsData,
    payload,
    setPayload,
    type,
    activeKey,
    setActiveKey,
    selectedArray,
    setSelectedArray,
  } = props;
  const [conversations, setConversations] = useState(tabsData);
  const conversation_source = payload.filters.conversation_source;
  const msgsCountObject = useSelector(msgsCount);

  useEffect(() => {
    setConversations(tabsData);
  }, [tabsData]);

  function callback(key) {
    setActiveKey(key);
    dispatch(setActiveConversation(""));
    dispatch(
      setTabFilters({
        ...payload,
        filters: {
          ...payload.filters,
          ticket_status: key !== "" ? key : null,
        },
        fetch_since: null,
      })
    );
    setPayload({
      ...payload,
      filters: {
        ...payload.filters,
        ticket_status: key !== "" ? key : null,
      },
      fetch_since: null,
    });
    history.push("/inbox/tickets");
    setSelectedArray([]);
  }

  const messagesCountDisplay = (source) => {
    const count =
      msgsCountObject[
        conversation_source !== null
          ? `${conversation_source}:${source}`
          : source
      ];

    if (count !== undefined && count !== null) {
      return <p className="subtab-count">{count === 0 ? "0" : count}</p>;
    }
  };

  return (
    <StyledSubPanel className="sub-tabpanel">
      <Tabs activeKey={activeKey} onTabClick={callback} centered>
        <TabPane tab={<div>All</div>} key="">
          <ConversationList
            selectedArray={selectedArray}
            setSelectedArray={setSelectedArray}
            type={type}
            data={conversations}
            payload={payload}
          />
        </TabPane>
        <TabPane
          tab={
            <div style={{ marginLeft: "-10px" }}>
              Unassigned {messagesCountDisplay("unassigned")}
            </div>
          }
          key="unassigned"
        >
          <ConversationList
            selectedArray={selectedArray}
            setSelectedArray={setSelectedArray}
            type={type}
            data={conversations}
            payload={payload}
          />
        </TabPane>
        <TabPane
          tab={
            <div style={{ marginLeft: "-10px" }}>
              Assigned {messagesCountDisplay("assigned")}
            </div>
          }
          key="assigned"
        >
          <ConversationList
            selectedArray={selectedArray}
            setSelectedArray={setSelectedArray}
            type={type}
            data={conversations}
            payload={payload}
          />
        </TabPane>
        <TabPane
          tab={
            <div style={{ marginLeft: "-10px" }}>
              Open {messagesCountDisplay("open")}
            </div>
          }
          key="open"
        >
          <ConversationList
            selectedArray={selectedArray}
            setSelectedArray={setSelectedArray}
            type={type}
            data={conversations}
            payload={payload}
          />
        </TabPane>
        <TabPane
          tab={
            <div style={{ marginLeft: "-10px" }}>
              Follow up {messagesCountDisplay("follow_up")}
            </div>
          }
          key="follow_up"
        >
          <ConversationList
            selectedArray={selectedArray}
            setSelectedArray={setSelectedArray}
            type={type}
            data={conversations}
            payload={payload}
          />
        </TabPane>
        <TabPane
          tab={
            <div style={{ marginLeft: "-10px" }}>
              Closed {messagesCountDisplay("closed")}
            </div>
          }
          key="closed"
        >
          <ConversationList
            selectedArray={selectedArray}
            setSelectedArray={setSelectedArray}
            type={type}
            data={conversations}
            payload={payload}
          />
        </TabPane>
      </Tabs>
    </StyledSubPanel>
  );
}
