import styled from "styled-components";

export const ConversationWrapper = styled.div`
  width: 100%;
  flex: 2;
  margin: 12px;
  position: relative;
  display: ${(props) => (props.replypanel ? "none" : "block")};

  > div {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    height: calc(100vh - 60px);
    position: relative;
    align-items: space-around;
    margin: 20px 0px 15px 0px;
    border-radius: 15px;
    position: relative;

    .input {
      &__field {
        display: block;
        border: 0;
        padding: 1em;
        border-radius: 0.4em;
        width: 100%;
        outline: none;
        color: #4b4b4b;
      }
      &__row {
        background-color: $inputRow;
        padding: 0.5em 2em;
      }
    }

    .message {
      position: relative;
      display: inline-block;
      max-width: 60%;

      &__row {
        margin-bottom: ${(props) =>
          props.conversationSource == "email" ? "2.5em" : "0.75em"};
        margin-right: 0.4em;
        margin-left: 0.7em;
        text-align: center;
        position: relative;

        .email-time {
          color: rgb(91, 91, 91);
          font-size: 12px;
        }
      }

      .actual-message {
        white-space: pre-wrap;
        word-wrap: break-word;
        text-align: left;

        .ant-collapse-header,
        .ant-collapse-content-box {
          padding: 0 !important;
          margin-top: 10px;
        }
      }

      &--customer {
        background: #bfdff1;
        border-radius: 1px 14px 14px 14px;
        float: left;
        color: #1f1f1f;
        border: 1px solid #1d8dcc80 !important;
        text-align: left;
        min-width: 200px;
        max-width: 450px;
        padding: 1em;
        margin-bottom: 1.5rem;
      }

      &--merchant {
        border-radius: 14px 1px 14px 14px !important;
        float: right;
        margin-right: 36px;
        color: #1f1f1f;
        background: #fff !important;
        text-align: left;
        min-width: 325px;
        max-width: 450px;
        padding: 1em 1em 2.25em 1em !important;
      }

      &--merchant.message-file {
        text-align: right;
      }

      &.message-file {
        padding: 0px;
        background-color: var(--color-background-dark);
        border: none;
        box-shadow: none;
      }
    }
  }

  .ant-drawer-header {
    padding: 15px 20px !important;
  }

  .ant-drawer-body {
    padding-top: 0px;
  }

  .email-drawer .ant-drawer-body{
    height: calc(100% - 200px);
  }
`;

export const StyledClosePreview = styled.div`
  width: ${(props) => (props.closed ? "31%" : "48.85%")};
  height: 91vh;
  left: 39%;
  position: fixed;
  top: 5vh;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;

  @media (min-width: 1500px) {
    width: ${(props) => (props.closed ? "31.5%" : "49.4%")};
    left: 38.5%;
  }
  @media (min-width: 1800px) {
    width: ${(props) => (props.closed ? "38.75%" : "53.85%")};
    left: 34.1%;
  }
  @media (min-width: 1900px) {
    width: ${(props) => (props.closed ? "41%" : "55%")};
    left: 32.9%;
  }
`;

export const StyledHeader = styled.div`
  position: sticky;
  top: 0;
  background-color: #fff;
  border: 1px solid #d5d5d5;

  .warning {
    background: #bb4747;
  }

  .safe {
    background: #1e8334;
  }
`;

export const StyledMessageContainer = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  flex: 1 1 0;
  overflow-y: hidden;
  flex-direction: column-reverse;
  padding: 15px 25px;
  padding-right: 20px;
  padding-bottom: 0px;
  border-left: 1px solid #d5d5d5;
  border-right: 1px solid #d5d5d5;
  background: #1d8dcc1a;
  position: relative;
  overflow-x: hidden;

  :hover {
    overflow-y: auto;
  }

  &.loading {
    align-items: center;
    justify-content: center;
  }

  .ant-skeleton {
    width: 90%;
  }

  .ant-skeleton-content {
    width: 80%;
  }

  .flip-loader {
    direction: rtl;
    .ant-skeleton-header {
      padding-left: 16px;
      padding-right: 0px;
    }
  }

  .countdown {
    position: absolute;
    top: 0;
    z-index: 99;
    width: 100%;
    text-align: center;
    color: #fff;
    left: -50%;
  }
`;

export const StyledTimer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 100%;
  z-index: 99;
`;

export const StyledInputContainer = styled.div`
  min-height: 20px;
  box-sizing: border-box;
  padding: 0px 15px 15px 15px;
  border: 1px solid #d5d5d5;
  border-top: none;
  background: #1d8dcc1a;
  position: relative;

  .compose-email {
    position: absolute !important;
    z-index: 9999 !important;
  }

  .ant-input:focus {
    border-color: #fff;
    border-right-width: 0px !important;
    outline: 0;
    box-shadow: none;
  }

  .ant-input[disabled] {
    background-color: var(--color-background-dark);
  }

  form {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .send-button {
    background: #fff;
    margin: 0px 5px 0px 15px;
    padding: 10px 0;
    border: none;
    display: flex;
    height: 51px;
    width: 51px;
    justify-content: center;
    align-items: center;

    :hover {
      background: rgba(29, 141, 204, 0.2);
    }
  }

  textarea {
    width: 100%;
    line-height: 1;
    padding: 8px 6px 0px;
    overflow-x: hidden;
    overflow-y: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    text-decoration: none;
    max-height: 100px;
    min-height: 20px;
    outline: 0;
    resize: none;
    transition: 0.2s padding ease-in-out;
    border: none;
    margin: 2px 0px;
    font-weight: 500;
    color: #474747;
  }
`;

export const StyledTopLayer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
  align-items: center;

  .assign-me-button {
    background-color: #4790bb;
    border-radius: 8px;
    color: #fff;
    font-size: 0.8em;
    border: 1px solid #4790bb;
  }

  .customer-title {
    color: #3c474e;
    text-transform: capitalize;
    font-size: 1.4em;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    height: 50px;
    align-items: center;

    .initial-avatar {
      color: #fff;
      background-color: var(--color-icon-background);
      font-weight: 400;
      margin-right: 6px;
      height: 40px;
      width: 40px;
      padding: 5px;
    }

    div {
      margin-left: 5px;
      color: #333333;
      line-height: 1;
      span {
        font-size: 14px;
        color: #5b5b5b;
        font-weight: 200;
      }
    }
  }
`;

export const StyledEmojiWrapper = styled.div`
  z-index: 99;
`;

export const StyledTextWrap = styled.div`
  background-color: #fff;
  display: flex;
  width: 100%;
  align-items: center;
  border: 1px solid #ccc;
  padding: 4px 10px;

  .message-icon {
    margin: 0px 6px 0px 12px;
  }
`;

export const StyledSkeletonWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--color-primary);
  font-size: 20px;
  text-align: center;

  div {
    width: 100%;
    height: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }

  img {
    width: 35%;
  }
`;

export const StyledTagsInput = styled.div`
  margin: 0px 10px;

  .react-tag-input {
    background: #d4d7dc;
    border: none;
  }

  .react-tag-input__tag {
    background: #3c474e4d;
  }

  .react-tag-input__tag__remove {
    background: #3c474e4d;
  }

  .react-tag-input__input {
    background: #d4d7dc;
  }

  input::placeholder {
    width: 200px;
    border: 1px dashed #ccc;
  }
`;

export const StyledDragComponent = styled.div`
  height: 100%;
  margin: 20px;
  border-radius: 15px;
  position: relative;
  background: rgb(235, 240, 244);
  display: flex;
  align-items: center;
  justify-content: center;

  .close-button {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 21px;
  }

  .anticon {
    text-align: left;
  }
`;

export const StyledUploadComponent = styled.div`
  height: ${(props) => (props.conversationSource !== "email" ? "94vh" : "60vh")};
  width: ${(props) => (props.conversationSource !== "email" ? "100%" : "120%")};
  position: absolute;
  bottom: 0;
  left: ${(props) => (props.conversationSource !== "email" ? "0" : "-50px")};
  background: rgb(235, 240, 244);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;

  .close-button {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 21px;
  }

  .anticon {
    text-align: left;
  }
`;

export const StyledWarning = styled.div`
  font-size: 0.7em;
  span {
    color: var(--color-red);
  }
`;

export const StyledInfo = styled.div`
  font-size: 0.7em;
  span {
    color: var(--color-primary);
  }

  .info-name {
    font-weight: 700;
    color: var(--color-primary);
  }
`;

export const StyledReply = styled.div`
  max-height: 300px;
  background: transparent;
  overflow-y: scroll;
  padding: 5px 20px 0;
  border-radius: 4px 4px 0 0;
  position: absolute;
  z-index: 10;
  bottom: 65px;
  right: 82px;
  left: 15px;

  .selected {
    background: #f0f2f6;
  }
`;

export const StyledEmailReply = styled.div`
  max-height: 300px;
  overflow-y: scroll;
  padding: 5px 5px 0;
  border-radius: 4px 4px 0 0;
  position: absolute;
  z-index: 99;
  right: 25px;
  left: 25px;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.5);

  li:hover, .selected {
    background: #f0f2f6;
  }
`;

export const StyledEmail = styled.p`
  margin-top: 10px;
  img {
    max-width: 300px !important;
    max-height: 300px !important;
  }
`;

export const StyledEmailAttachment = styled.div`
  display: flex;
  width: 85%;
  background: #eeeeee;
  padding: 10px;
  justify-content: space-between;
  margin-top: 10px;

  div {
    display: flex;
  }

  a {
    color: #474747;
    font-weight: 600;
    margin-left: 10px;
  }

  p {
    margin: 0;
    cursor: pointer;
    color: #474747;
    font-size: 12px;
  }

  :last-child {
    margin-bottom: 60px;
  }
`;

export const StyledCloseButton = styled.p`
	:hover{
		background: #dadada;
	}
`

export const StyledEmailGroups = styled.div`
	background: #fff;
	position: absolute;
	left: 7%;
	z-index: 10;
    width: 91%;
	max-height: 400px;
	overflow: scroll;

	div{
		cursor: pointer;
		padding: 0px 10px;

		:hover{
			background: #f5f5f5;
		}
	}
`
