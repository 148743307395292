import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Tooltip, Input, Dropdown, Menu } from "antd";
import { openDrawer } from "@store-inbox/action";
import { setSearchFilters } from "@store-inbox/MainPanel/action";
import { ReactComponent as SearchIcon } from "@assets/icons/main/search.svg";
import { ReactComponent as FilterIcon } from "@assets/icons/main/filter.svg";
import { ReactComponent as FilterActiveIcon } from "@assets/icons/main/filter-active.svg";
import { ReactComponent as SearchActiveIcon } from "@assets/icons/main/x.svg";
// import { ReactComponent as InitiateIcon } from "@assets/icons/main/initiatechat.svg";
import { ReactComponent as BotIcon } from "@assets/icons/main/bot.svg";
import { ReactComponent as BotMessageIcon } from "@assets/icons/main/bot-message.svg";
import { ReactComponent as LeftArrowIcon } from "@assets/icons/main/arrowleft.svg";
import { StyledSearchBar } from "./styled";
import CreateTicket from "../CreateTicket";
import { PlusCircleFilled } from "@ant-design/icons";
import { getMsgsCount, resetMail } from "@store-inbox/MainPanel/action";
import { derivedConnectionsSelector } from "../utils";

export default function CustomizedInputBase(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [activeSearch, setActiveSearch] = useState(false);
  const [activeFilters, setActiveFilters] = useState(false);
  const [botStatus, setBotStatus] = useState(null);
  const [validity, setValidity] = useState(false);
  const [createTicket, setCreateTicket] = useState(false);
  const settings = useSelector(derivedConnectionsSelector);
  const {
    data,
    payload,
    setPayload,
    activeKey,
    setActiveKey,
    newChat,
    setNewChat,
    setTemplate,
    phone,
    setPhone,
    text,
    setText,
    allTags,
    setCreateNewEmail
  } = props;

  const handleFilterDrawer = () => {
    dispatch(openDrawer(true));
  };

  const onTextChange = (e) => {
    setText(e.target.value);
  };

  const onPhoneChange = (e) => {
    if (e.target.validity.valid) {
      setPhone(e.target.value.slice(-10));
      setValidity(false);
    } else {
      setPhone("");
      setValidity(true);
    }
  };

  useEffect(() => {
    if (payload) {
      const {
        date_filter_end,
        date_filter_start,
        initiated_by,
        priority_filter,
        unread,
      } = payload.filters;
      if (
        date_filter_end ||
        date_filter_start ||
        initiated_by ||
        priority_filter ||
        unread
      ) {
        setActiveFilters(true);
      } else {
        setActiveFilters(false);
      }
    }
  }, [payload]);

  useEffect(() => {
    if (phone.length === 10) {
      setTemplate(true);
    } else {
      setTemplate(false);
    }
  }, [phone]);

  const createNewChat = (key) => {
    if (key === "initiate_chat") {
      setNewChat(true);
      setPhone("");
    } else if(key === "compose_email") {
      dispatch(resetMail());
      setCreateNewEmail(true);
    } else {
      setCreateTicket(true);
    }
  };

  const items = [
    {
      label: "Send Whatsapp Template",
      key: "initiate_chat",
      display: true
    },
    {
      label: "Create New Ticket",
      key: "create_new_ticket",
      display: true
    },
    {
      label: "Compose Email",
      key: "compose_email",
      display: settings?.email_accounts?.length > 0
    }
  ];

  const menu = (
    <Menu>
      {items.filter(a=>a.display).map((item) => (
        <Menu.Item key={item.key} onClick={() => createNewChat(item.key)}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const onSearch = () => {
    if (text !== "") {
      setActiveSearch(true);
      dispatch(
        setSearchFilters({
          ...payload,
          fetch_since: null,
          page_size: 25,
          filters: {
            ...payload.filters,
            search: text,
            ticket_status: "assigned_to_bot",
          },
        })
      );
      setActiveKey("");
      setPayload({
        ...payload,
        fetch_since: null,
        page_size: 25,
        filters: {
          ...payload.filters,
          search: text,
          ticket_status: "assigned_to_bot",
        },
      });
      history.push(`/inbox/tickets`);
    }
  };

  useEffect(() => {
    if (text === "") {
      setActiveSearch(false);
      dispatch(
        setSearchFilters({
          ...payload,
          fetch_since: null,
          page_size: 25,
          filters: {
            ...payload.filters,
            search: null,
            ticket_status: activeKey !== "" ? activeKey : null,
          },
        })
      );
      setPayload({
        ...payload,
        fetch_since: null,
        page_size: 25,
        filters: {
          ...payload.filters,
          search: null,
          ticket_status: activeKey !== "" ? activeKey : null,
        },
      });
    }
  }, [text]);

  const onResetSearch = () => {
    setActiveSearch(false);
    setText("");
  };

  const onReset = () => {
    dispatch(
      setSearchFilters({
        ...payload,
        fetch_since: null,
        page_size: 25,
        filters: {
          ...payload.filters,
          date_filter_start: null,
          date_filter_end: null,
          initiated_by: null,
          priority_filter: null,
          unread: null,
          ticket_status: activeKey !== "" ? activeKey : null,
          tag_filter: null,
          agent_ids: null,
          sort_by: null,
        },
      })
    );
    setPayload({
      ...payload,
      fetch_since: null,
      page_size: 25,
      filters: {
        ...payload.filters,
        date_filter_start: null,
        date_filter_end: null,
        initiated_by: null,
        priority_filter: null,
        unread: null,
        ticket_status: activeKey !== "" ? activeKey : null,
        tag_filter: null,
        agent_ids: null,
      },
    });
    dispatch(getMsgsCount({selectedAgents: null, refresh: false}));
  };

  useEffect(() => {
    if (
      payload &&
      payload.filters &&
      payload.filters.ticket_status == "assigned_to_bot"
    ) {
      setBotStatus("assigned_to_bot");
    } else {
      setBotStatus(null);
    }
  }, [payload]);

  return (
    <div>
      <StyledSearchBar>
        {newChat ? (
          <div style={{ width: "100%" }}>
            <div style={{ display: "flex", width: "100%" }}>
              <Tooltip title="Go Back" placement="bottom">
                <div className="filter-buttons" style={{ borderRadius: "4px" }}>
                  <Button
                    className="icon-button"
                    onClick={() => {
                      setNewChat(false);
                      setPhone("");
                    }}
                    shape="square"
                    icon={<LeftArrowIcon />}
                    style={{ marginLeft: "0" }}
                    type="link"
                  />
                </div>
              </Tooltip>
              <Input
                type="tel"
                defaultValue={phone}
                // maxLength={10}
                // pattern="[1-9]{1}[0-9]{9}"
                placeholder="Enter Phone Number"
                onChange={onPhoneChange}
                style={{
                  margin: "0 0 0 3%",
                  fontSize: "13px",
                  color: "#474747",
                  padding: "0 12px",
                  width: "100%",
                }}
              />
            </div>
            {validity && (
              <p
                style={{
                  margin: "10px 0px 0px 74px",
                  color: "var(--color-red)",
                }}
              >
                Enter a Valid Number
              </p>
            )}
          </div>
        ) : (
          <Fragment>
            <Tooltip title="Bot Tickets" placement="bottom">
              <div className="filter-buttons" style={{ borderRadius: "4px" }}>
                <BotMessageIcon
                  style={{
                    position: "absolute",
                    right: "-10px",
                    top: "-15px",
                    stroke: "none",
                  }}
                />
                <Button
                  className="icon-button"
                  onClick={() => {
                    dispatch(
                      setSearchFilters({
                        ...payload,
                        filters: {
                          ...payload.filters,
                          ticket_status:
                            botStatus !== null ? null : "assigned_to_bot",
                        },
                        fetch_since: null,
                        page_size: 25,
                      })
                    );
                    setPayload({
                      ...payload,
                      filters: {
                        ...payload.filters,
                        ticket_status:
                          botStatus !== null ? null : "assigned_to_bot",
                      },
                      fetch_since: null,
                      page_size: 25,
                    });
                  }}
                  style={{
                    background:
                      botStatus !== null
                        ? "var(--color-primary)"
                        : "transparent",
                  }}
                  shape="square"
                  icon={
                    <BotIcon
                      style={{
                        stroke:
                          botStatus == null ? "var(--color-primary)" : "#fff",
                      }}
                    />
                  }
                  type="link"
                />
              </div>
            </Tooltip>
            <div className="search">
              <div className="search-wrapper">
                <Input
                  placeholder="Search names, phone numbers..."
                  bordered={false}
                  value={text}
                  onChange={onTextChange}
                  onPressEnter={onSearch}
                />
                <Tooltip
                  title={activeSearch ? "clear" : "search"}
                  placement="bottom"
                >
                  <div className="filter-buttons" style={{ border: "none" }}>
                    {activeSearch ? (
                      <Button
                        className="icon-button"
                        onClick={onResetSearch}
                        icon={<SearchActiveIcon />}
                        type="link"
                      />
                    ) : (
                      <Button
                        className="icon-button"
                        onClick={onSearch}
                        icon={<SearchIcon style={{ color: "#fff" }} />}
                        type="link"
                      />
                    )}
                  </div>
                </Tooltip>
              </div>
            </div>
            <Tooltip title="Filter" placement="bottom">
              <div className="filter-buttons" style={{ borderRadius: "4px" }}>
                <Button
                  className="icon-button"
                  onClick={handleFilterDrawer}
                  shape="square"
                  icon={<FilterIcon />}
                  type="link"
                />
                {activeFilters ? (
                  <Button
                    className="filter-active-button"
                    onClick={onReset}
                    shape="square"
                    icon={<FilterActiveIcon />}
                    type="link"
                    size="small"
                  />
                ) : null}
              </div>
            </Tooltip>
          </Fragment>
        )}
      </StyledSearchBar>
      {activeSearch ? (
        <span
          style={{ color: "#959595", margin: "5px 10px", fontStyle: "italic" }}
        >
          <i>
            {" "}
            {data.length} search results for '{text}'
          </i>
        </span>
      ) : (
        ""
      )}
      {!newChat && (
        <Dropdown overlay={menu} placement="topRight">
          <div
            style={{
              position: "fixed",
              bottom: "10px",
              left: "430px",
              borderRadius: "50%",
              zIndex: '9',
              cursor: "pointer",
            }}
          >
            <PlusCircleFilled
              style={{ fontSize: "40px", color: "var(--color-primary)" }}
            />
          </div>
        </Dropdown>
      )}
      {createTicket && (
        <CreateTicket
          createTicket={createTicket}
          setCreateTicket={setCreateTicket}
          allTags={allTags}
          payload={payload}
        />
      )}
    </div>
  );
}
