import { put, call, all, takeLatest } from "@redux-saga/core/effects";
import * as types from "./types";
import * as conversationTypes from "../ConversationPanel/types";
import * as api from "@utils/api";

function* init() {
  yield all([put({ type: types.INIT })]);
}

function* getOrdersSaga(action) {
  const { payload } = action;
  if (payload !== "") {
    try {
      const customerTicketsData = yield call(api.getAllTickets, payload);
      yield put({
        type: types.GET_CUSTOMER_TICKETS_SUCCESS,
        payload: customerTicketsData.data.data,
      });
    } catch (err) {
      yield put({
        type: types.GET_CUSTOMER_TICKETS_ERROR,
        payload: err,
      });
    }

    try {
      const orderData = yield call(api.getOrders, payload);
      yield put({
        type: types.GET_ORDERS_SUCCESS,
        payload: orderData.data.data,
      });
    } catch (err) {
      yield put({
        type: types.GET_ORDERS_ERROR,
        payload: err,
      });
    }

    try {
      const abandonedOrderData = yield call(api.getAbandoned, payload);
      yield put({
        type: types.GET_ABANDONED_ORDERS_SUCCESS,
        payload: abandonedOrderData.data.data,
      });
    } catch (err) {
      yield put({
        type: types.GET_ABANDONED_ORDERS_ERROR,
        payload: err,
      });
    }

    try {
      yield put({ type: types.GET_CUSTOMER_DETAILS });
      const customerData = yield call(api.getCustomer, payload);
      yield put({
        type: types.GET_CUSTOMER_DETAILS_SUCCESS,
        payload: customerData.data.data,
      });
    } catch (err) {
      yield put({
        type: types.GET_CUSTOMER_DETAILS_ERROR,
        payload: err,
      });
    }
  }
}

function* getLabelsSaga(action) {
  const { payload } = action;
  if (payload !== "") {
    try {
      const labelsData = yield call(api.getFieldValues, payload);
      yield put({
        type: types.GET_FIELD_VALUES_SUCCESS,
        payload: labelsData.data.data,
      });
    } catch (err) {
      yield put({
        type: types.GET_FIELD_VALUES_ERROR,
        payload: err,
      });
    }
  }
}

function* postLabelsSaga(action) {
  const { payload } = action;
  if (payload !== "") {
    try {
      const labelsData = yield call(api.postLabelsApi, payload);
      yield put({
        type: types.POST_TICKET_LABELS_SUCCESS,
        payload: labelsData.data.data,
      });
    } catch (err) {
      if (err.response) {
        yield put({
          type: types.POST_TICKET_LABELS_ERROR,
          payload: {
            message: err.response.data.error.message, // Extracting the error message from the response
            statusCode: err.response.status, // Including the status code in the payload
          },
        });
      } else {
        yield put({
          type: types.POST_TICKET_LABELS_ERROR,
          payload: err,
        });
      }
    }
  }
}

function* getLinkedTicketsSaga(action) {
  const { payload } = action;
  if (payload !== "") {
    try {
      const linkedTicketsData = yield call(api.getLinkedTicketsapi, payload);
      yield put({
        type: types.GET_LINKED_TICKETS_SUCCESS,
        payload: linkedTicketsData.data.data,
      });
    } catch (err) {
      yield put({
        type: types.GET_LINKED_TICKETS_ERROR,
        payload: err,
      });
    }

    try {
      const notes = yield call(api.getNotesAPI, payload);
      yield put({
        type: types.GET_NOTES_SUCCESS,
        payload: notes.data.data,
      });
    } catch (err) {
      if (err.response) {
        yield put({
          type: types.GET_NOTES_ERROR,
          payload: {
            message: err.response.data.error.message, // Extracting the error message from the response
            statusCode: err.response.status, // Including the status code in the payload
          },
        });
      } else {
        yield put({
          type: types.GET_NOTES_ERROR,
          payload: err,
        });
      }
    }
  }
}

function* postLinkedTicketsSaga(action) {
  const { payload } = action;
  if (payload !== "") {
    try {
      const linkedTicketsData = yield call(api.postLinkedTicketsapi, payload);
      yield put({
        type: types.POST_LINKED_TICKETS_SUCCESS,
        payload: linkedTicketsData.data.data,
      });
    } catch (err) {
      if (err.response) {
        yield put({
          type: types.POST_LINKED_TICKETS_ERROR,
          payload: {
            message: err.response.data.error.message, // Extracting the error message from the response
            statusCode: err.response.status, // Including the status code in the payload
          },
        });
      } else {
        yield put({
          type: types.POST_LINKED_TICKETS_ERROR,
          payload: err,
        });
      }
    }
  }
}

function* watchActiveConversationLoad() {
  yield takeLatest([conversationTypes.ACTIVE_ID], getOrdersSaga);
}

function* watchActiveConversationLabelsLoad() {
  yield takeLatest(
    [conversationTypes.SET_ACTIVE_CONVERSATION_ID],
    getLabelsSaga
  );
}

function* watchPostTicketLabels() {
  yield takeLatest([types.POST_TICKET_LABELS], postLabelsSaga);
}

function* watchLinkedTicketsLoad() {
  yield takeLatest(
    [conversationTypes.SET_ACTIVE_CONVERSATION_ID],
    getLinkedTicketsSaga
  );
}

function* watchLinkedTickets() {
  yield takeLatest([types.POST_LINKED_TICKETS], postLinkedTicketsSaga);
}

export default [
  init,
  watchActiveConversationLoad,
  watchActiveConversationLabelsLoad,
  watchPostTicketLabels,
  watchLinkedTicketsLoad,
  watchLinkedTickets,
];
