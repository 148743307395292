import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Search from "../Search/Search.component";
import { setTabFilters } from "@store-inbox/MainPanel/action";
import { MainPanelWrapper } from "../styled";
import { Select, Tabs } from "antd";
import Online from "../Online/Online.component";
import { ReactComponent as WhatsappIcon } from "@assets/icons/main/whatsapptab.svg";
import { ReactComponent as InstagramIcon } from "@assets/icons/main/instagramtab.svg";
import { ReactComponent as FacebookIcon } from "@assets/icons/main/facebooktab.svg";
import { ReactComponent as EmailIcon } from "@assets/icons/main/emailtab.svg";
import SubTabPanel from "./SubTabPanel";
import { drawerSelector } from "@store-inbox/selector";
import FilterPanel from "@components-inbox/common/FilterPanel/FilterPanel.component";
// import TagsFilter from "../TagsFilter/TagsFilter.component";
import InitiateChat from "../InitiateChat/InitiateChat.component";
import MultiSelect from "../Multiselect";
// import { getPopularTags } from "@store-inbox/MainPanel/action";
import {
  popularTagsDataSelector,
  ticketListApiSelector,
} from "@store-inbox/MainPanel/selector";
// import { wsStatus } from "@store-inbox/selector";
import { setActiveConversation } from "@store-inbox/ConversationPanel/action";
import { MdCall } from "react-icons/md";
import Email from "../../Email";

const { TabPane } = Tabs;

export default function FullWidthTabs(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    tabsData,
    payload,
    setPayload,
    activeKey,
    setActiveKey,
    newChat,
    setNewChat,
    phone,
    setPhone,
    template,
    setTemplate,
    selectedArray,
    setSelectedArray,
  } = props;

  const [conversations, setConversation] = useState(tabsData);
  const [allTags, setAllTags] = useState([]);
  const [text, setText] = useState("");
  const [activeSource, setActiveSource] = useState(null);
  const [createNewEmail, setCreateNewEmail] = useState(false);

  const ticketSourceSelector = useSelector(ticketListApiSelector);
  const popularTags = useSelector(popularTagsDataSelector);
  const drawerStatus = useSelector(drawerSelector);
  // const status = useSelector(wsStatus);

  const { Option } = Select;
  // useEffect(() => {
  //   if (status) {
  //     dispatch(getPopularTags());
  //   }
  // }, [dispatch, status]);

  useEffect(() => {
    if (popularTags) {
      let temp = [];
      for (let i = 0; i < popularTags.length; i++) {
        temp.push(
          <Option key={popularTags[i].tag}>{popularTags[i].tag}</Option>
        );
      }
      setAllTags(temp);
    }
  }, [popularTags]);

  useEffect(() => {
    setConversation(tabsData);
  }, [tabsData]);

  useEffect(() => {
    if (ticketSourceSelector && Object.keys(ticketSourceSelector).length > 0) {
      if (
        ticketSourceSelector.data.conversation_source == activeSource ||
        (ticketSourceSelector.data.conversation_source == null &&
          activeSource == "")
      ) {
        if (
          ticketSourceSelector.data.ticket_status == activeKey ||
          (ticketSourceSelector.data.ticket_status == null && activeKey == "")
        ) {
          setConversation(tabsData);
        }
      }
    }
  }, [tabsData]);

  function callback(key) {
    dispatch(setActiveConversation(""));
    dispatch(
      setTabFilters({
        ...payload,
        filters: {
          ...payload.filters,
          conversation_source: key !== "" ? key : null,
        },
        fetch_since: null,
      })
    );
    setPayload({
      ...payload,
      filters: {
        ...payload.filters,
        conversation_source: key !== "" ? key : null,
      },
      fetch_since: null,
    });
    setActiveSource(key);
    history.push(`/inbox/tickets`);
    setSelectedArray([]);
  }

  return (
    <MainPanelWrapper className="main-tabpanel">
      <Online payload={payload} />
      {selectedArray.length > 0 ? (
        <MultiSelect
          selectedArray={selectedArray}
          setSelectedArray={setSelectedArray}
          setNewChat={setNewChat}
          activeKey={activeKey}
          setTemplate={setTemplate}
        />
      ) : (
        <Search
          data={conversations}
          phone={phone}
          setPhone={setPhone}
          newChat={newChat}
          setNewChat={setNewChat}
          template={template}
          setTemplate={setTemplate}
          payload={payload}
          setPayload={setPayload}
          activeKey={activeKey}
          setActiveKey={setActiveKey}
          text={text}
          setText={setText}
          allTags={allTags}
          setCreateNewEmail={setCreateNewEmail}
        />
      )}
      {newChat ? (
        <InitiateChat
          template={template}
          phone={phone}
          setNewChat={setNewChat}
          setTemplate={setTemplate}
          selectedArray={selectedArray}
          setSelectedArray={setSelectedArray}
        />
      ) : (
        <Fragment>
          {drawerStatus ? (
            <FilterPanel
              payload={payload}
              setPayload={setPayload}
              setActiveKey={setActiveKey}
              allTags={allTags}
              text={text}
            />
          ) : (
            <Fragment>
              {/* {selectedArray.length <=0 && <TagsFilter payload={payload} setPayload={setPayload} activeKey={activeKey} setActiveKey={setActiveKey} /> } */}
              <Tabs defaultActiveKey="1" onTabClick={callback} centered>
                <TabPane tab={<Fragment>All</Fragment>} key="">
                  <SubTabPanel
                    selectedArray={selectedArray}
                    setSelectedArray={setSelectedArray}
                    activeKey={activeKey}
                    setActiveKey={setActiveKey}
                    tabsData={conversations}
                    payload={payload}
                    setPayload={setPayload}
                    type="all"
                  />
                </TabPane>
                <TabPane tab={<WhatsappIcon />} key="whatsapp">
                  <SubTabPanel
                    selectedArray={selectedArray}
                    setSelectedArray={setSelectedArray}
                    activeKey={activeKey}
                    setActiveKey={setActiveKey}
                    tabsData={conversations}
                    payload={payload}
                    setPayload={setPayload}
                    type="whatsapp"
                  />
                </TabPane>
                <TabPane tab={<EmailIcon />} key="email">
                  <SubTabPanel
                    selectedArray={selectedArray}
                    setSelectedArray={setSelectedArray}
                    activeKey={activeKey}
                    setActiveKey={setActiveKey}
                    tabsData={conversations}
                    payload={payload}
                    setPayload={setPayload}
                    type="email"
                  />
                </TabPane>
                <TabPane tab={<InstagramIcon />} key="instagram">
                  <SubTabPanel
                    selectedArray={selectedArray}
                    setSelectedArray={setSelectedArray}
                    activeKey={activeKey}
                    setActiveKey={setActiveKey}
                    tabsData={conversations}
                    payload={payload}
                    setPayload={setPayload}
                    type="instagram"
                  />
                </TabPane>
                <TabPane tab={<FacebookIcon />} key="messenger">
                  <SubTabPanel
                    selectedArray={selectedArray}
                    setSelectedArray={setSelectedArray}
                    activeKey={activeKey}
                    setActiveKey={setActiveKey}
                    tabsData={conversations}
                    payload={payload}
                    setPayload={setPayload}
                    type="messenger"
                  />
                </TabPane>
                <TabPane
                  tab={
                    <MdCall
                      style={{
                        fontSize: "24px",
                        color: "var(--color-primary)",
                      }}
                    />
                  }
                  key="phone"
                >
                  <SubTabPanel
                    selectedArray={selectedArray}
                    setSelectedArray={setSelectedArray}
                    activeKey={activeKey}
                    setActiveKey={setActiveKey}
                    tabsData={conversations}
                    payload={payload}
                    setPayload={setPayload}
                    type="phone"
                  />
                </TabPane>
              </Tabs>
            </Fragment>
          )}
        </Fragment>
      )}
      {createNewEmail && <Email createNewEmail={createNewEmail} setCreateNewEmail={setCreateNewEmail} />}
    </MainPanelWrapper>
  );
}
