import { SCOPE } from "./types";
import get from "lodash/get";
import { createSelector } from "reselect";

const selector = (state) => get(state, SCOPE);

export const orderDataSelector = createSelector(
  selector,
  (store) => store.orders.response
);
export const abandonedOrderDataSelector = createSelector(
  selector,
  (store) => store.abandonedOrders.response
);
export const customerDetailsDataSelector = createSelector(
  selector,
  (store) => store.customerDetails.response
);
export const customerDetailsLoaderSelector = createSelector(
  selector,
  (store) => store.customerDetails.loading
);
export const ordersLoaderSelector = createSelector(
  selector,
  (store) => store.orders.loading
);
export const allTicketsLoaderSelector = createSelector(
  selector,
  (store) => store.allTickets.loading
);
export const allTicketsDataSelector = createSelector(
  selector,
  (store) => store.allTickets.response
);
export const labelsDataSelector = createSelector(
  selector,
  (store) => store.labels.response
);
export const labelsLoaderSelector = createSelector(
  selector,
  (store) => store.labels.loading
);
export const labelsErrorSelector = createSelector(
  selector,
  (store) => store.labels.error
);
export const linkedTicketsDataSelector = createSelector(
  selector,
  (store) => store.linkedTickets.response
);
export const linkedTicketsLoaderSelector = createSelector(
  selector,
  (store) => store.linkedTickets.loading
);
export const linkedTicketsErrorSelector = createSelector(
  selector,
  (store) => store.linkedTickets.error
);
export const linkedTicketsApiHitSelector = createSelector(
  selector,
  (store) => store.linkedTickets.apiHit
);
export const notesSelector = createSelector(
  selector,
  (store) => store.notes.response
);

export default selector;
