import React, { useEffect, useState } from "react";
import { Dropdown, Input, Menu, Modal, Select } from "antd";
import { derivedConnectionsSelector } from "@components-inbox/MainPanel/utils";
import { derivedAllAgentsDataSelector } from "@components-inbox/ConversationPanel/utils";
import { useDispatch, useSelector } from "react-redux";
import { StyledCreateTicket } from "./styled";
import TextArea from "antd/lib/input/TextArea";
import { DownOutlined } from "@ant-design/icons";
import { createNewTicketAction } from "@store-inbox/MainPanel/action";
import { ticketCreateStatusSelector } from "@store-inbox/MainPanel/selector";
import { toast } from "react-toastify";

function CreateTicket(props) {
  const { createTicket, setCreateTicket, allTags, payload } = props;

  const settings = useSelector(derivedConnectionsSelector);
  const derivedAgents = useSelector(derivedAllAgentsDataSelector);
  const ticketStatus = useSelector(ticketCreateStatusSelector);

  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [notes, setNotes] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [tags, setTags] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState([]);
  const [allAgents, setAllAgents] = useState([]);
  const [submitValue, setSubmitValue] = useState("Create Ticket");
  const [creatingTicket, setCreatingTicket] = useState(false);

  toast.configure();

  const dispatch = useDispatch();

  const items = [
    {
      label: "Create Ticket",
      key: "create",
    },
    {
      label: "Create and Close Ticket",
      key: "create_and_close",
    },
    {
      label: "Create, Close and Send CSAT",
      key: "create_close_and_send_csat",
    },
  ];

  const menu = (
    <Menu>
      {items.map((item) => (
        <Menu.Item key={item.key} onClick={() => {createNewTicket(item.key);setSubmitValue(item.label)}}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleDeleteTag = (e) => {
    const index = tags.indexOf(e);
    const tempTags = [...tags];
    tempTags.splice(index, 1);
    setTags(tempTags);
  };

  useEffect(() => {
    if (derivedAgents) {
      let temp = [];
      for (let i = 0; i < derivedAgents.length; i++) {
        temp.push({
          value: derivedAgents[i].id,
          label: derivedAgents[i].first_name + derivedAgents[i].last_name,
        });
      }
      setAllAgents(temp);
      if (payload.filters) {
        setSelectedAgent(String(settings.current_user_id));
      }
    }
  }, [settings, derivedAgents, payload]);

  useEffect(() => {
    if (ticketStatus && ticketStatus !== null && creatingTicket) {
      if (ticketStatus.success) {
        toast.success("Ticket has been created", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setCreateTicket(false);
      } else {
        if (ticketStatus.data) {
          toast.error(` ${ticketStatus.data}`, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        } else {
          toast.error("Something went wrong. Please try again.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        }
      }
      setCreatingTicket(false);
    }
  }, [ticketStatus]);

  const createNewTicket = (key) => {
    dispatch(
      createNewTicketAction({
        email: email,
        phone: phone,
        subject: subject,
        agent_id: String(selectedAgent),
        notes: notes,
        tags: tags.join(","),
        customer_first_name: firstName,
        customer_last_name: lastName,
        set_status: key,
      })
    );
    setCreatingTicket(true);
  };

  return (
    <StyledCreateTicket>
      <Modal
        maskClosable={false}
        footer={
          <Dropdown.Button
            type="primary"
            loading={creatingTicket}
            icon={<DownOutlined />}
            overlay={menu}
            style={{ margin: "10px" }}
            onClick={() => createNewTicket(items.filter((a) => a.label == submitValue)[0].key)}
          >
            {submitValue}
          </Dropdown.Button>
        }
        centered
        title={"Create a new ticket"}
        className="ticket-modal"
        style={{ height: "85vh" }}
        visible={createTicket}
        onCancel={() => {
          setCreateTicket(false);
        }}
      >
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ marginBottom: "16px", marginRight: "20px", flex: 1 }}>
            <p style={{ marginBottom: "5px" }}>Customer First Name</p>
            <Input
              placeholder="Customer First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div style={{ marginBottom: "16px", flex: 1 }}>
            <p style={{ marginBottom: "5px" }}>Customer Last Name</p>
            <Input
              value={lastName}
              placeholder="Customer Last Name"
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ marginBottom: "16px", marginRight: "20px", flex: 1 }}>
            <p style={{ marginBottom: "5px" }}>Phone</p>
            <Input
              placeholder="Phone"
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div style={{ marginBottom: "16px", flex: 1 }}>
            <p style={{ marginBottom: "5px" }}>Email</p>
            <Input
              placeholder="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div style={{ marginBottom: "16px" }}>
          <p style={{ marginBottom: "5px" }}>Subject</p>
          <Input
            placeholder="Subject"
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </div>

        <div style={{ marginBottom: "16px" }}>
          <p style={{ marginBottom: "5px" }}>Agents</p>
          <Select
            size="large"
            style={{ width: "100%" }}
            defaultValue={settings.current_user_id}
            onChange={(e) => setSelectedAgent(e)}
            options={allAgents}
          />
          {/* <Select
            allowClear={true}
            size="large"
            style={{
              width: "100%",
              fontSize: "16px",
            }}
            placeholder="Select Agents"
            value={
              selectedAgents &&
              selectedAgents !== null &&
              selectedAgents.length > 0
                ? selectedAgents.map((a) => a)
                : []
            }
            placement="bottomLeft"
            onSelect={(e) => setSelectedAgents([...selectedAgents, e])}
            onDeselect={(e) => handleDeleteAgent(e)}
          >
            {allAgents}
          </Select> */}
        </div>
        <div style={{ marginBottom: "16px" }}>
          <p style={{ marginBottom: "5px" }}>Notes</p>
          <TextArea
            placeholder="Notes"
            style={{ width: "100%" }}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </div>

        <div>
          <p style={{ marginBottom: "5px" }}>Tags</p>
          <Select
            allowClear={true}
            mode="multiple"
            size="large"
            style={{
              width: "100%",
              fontSize: "16px",
            }}
            placeholder="Select Tags"
            value={tags !== null && tags.length > 0 ? tags.map((a) => a) : []}
            placement="topLeft"
            onSelect={(e) => setTags([...tags, e])}
            onDeselect={(e) => handleDeleteTag(e)}
          >
            {allTags}
          </Select>
        </div>
      </Modal>
    </StyledCreateTicket>
  );
}

export default CreateTicket;
