import React, { useState, useRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { List, Avatar, Skeleton, Button, Dropdown, Menu, Divider } from 'antd';
import Icon, { CaretUpOutlined } from '@ant-design/icons';
import { getInitials } from '@utils/functions';
import moment from 'moment';
import { PaperClipOutlined } from '@ant-design/icons';
import { ReactComponent as Video } from '@assets/icons/chatlist/video.svg';
import { ReactComponent as Voice } from '@assets/icons/chatlist/mic.svg';
import { ReactComponent as Audio } from '@assets/icons/message/headphones.svg';
import { ReactComponent as Image } from '@assets/icons/chatlist/image.svg';
import { activeConversationData } from '@store-inbox/ConversationPanel/selector';
import { ticketListApiSelector } from '@store-inbox/MainPanel/selector';
import { loadingSelector } from '@store-inbox/MainPanel/selector';
import { setPagination, markAsUnread } from '@store-inbox/MainPanel/action';
import { StyledListWrap, StyledListItem, StyledListMeta, StyledSource } from '../styled';
import Whatsapp from '@assets/icons/main/whatsapp.svg';
import Instagram from '@assets/icons/main/instagram.svg';
import Facebook from '@assets/icons/main/facebook.svg';
import EmailIcon from '@assets/icons/main/email.svg';
import { derivedConnectionsSelector } from '../utils';
import { waContentToHtml } from '@components-inbox/ConversationPanel/Conversation/index';
import ReactHtmlParser from 'react-html-parser';
import { ReactComponent as Empty } from '@assets/icons/nil.svg';
import { ReactComponent as SentIcon } from '@assets/icons/message/sent.svg';
import { ReactComponent as SeenIcon } from '@assets/icons/message/seen.svg';
import { ReactComponent as DeliveredIcon } from '@assets/icons/message/newdelivered.svg';
import { ReactComponent as DownArrowIcon } from '@assets/icons/main/downarrow.svg';
import { ReactComponent as CheckIcon } from '@assets/icons/main/check.svg';
import { setActiveConversation } from '@store-inbox/ConversationPanel/action';
// import { wsStatus } from '@store-inbox/selector';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

moment.updateLocale('en', {
    relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: '~5s',
        ss: '%ds',
        m: "1m",
        mm: "%dm",
        h: "1h",
        hh: "%dh",
        d: "1d",
        dd: "%dd",
        w: "1w",
        ww: "%dw",
        M: "1mon",
        MM: "%dmon",
        y: "1y",
        yy: "%dy"
    }
});

export default function AlignItemsList(props) {
    const { data, type, payload, selectedArray, setSelectedArray } = props;
    const listInnerRef = useRef();
    const dispatch = useDispatch();
    const loading = useSelector(loadingSelector);
    const activeConversation = useSelector(activeConversationData);
    const connections = useSelector(derivedConnectionsSelector);
    
    const datestamp = useSelector(ticketListApiSelector);

    const [unreadId,setUnreadId] = useState([]);
    const [reachedBottom, setReachedBottom] = useState(false);
    const [ticketsLoading, setTicketsLoading] = useState(null);
    const ticketsRef = useRef([]);

    toast.configure();

    const description = (item) => {
        if((item.conversation.conversation_source != "email")){
            if (item.conversation.last_message.attachment === null) {
                return <div style={{display: 'flex'}}>
                            {item.conversation.last_message.actor === "merchant" && 
                                <Fragment>
                                    {(item.conversation.conversation_source === "instagram" || item.conversation.conversation_source === "messenger" || item.conversation.last_message.delivered) ? null: <div style={{display: 'flex',marginTop: '5px'}}><Icon component={SentIcon} style={{marginRight: '5px'}} /></div>}
                                    {((item.conversation.conversation_source === "instagram" || item.conversation.conversation_source === "messenger" || item.conversation.last_message.delivered) && !item.conversation.last_message.read) ? <div><Icon component={DeliveredIcon} style={{marginRight: '5px',marginTop: '5px'}} /></div>: null}
                                    {item.conversation.last_message.read ? <Fragment><Icon component={SeenIcon} style={{marginTop: '5px',marginRight: '5px'}} /></Fragment> : null}
                                </Fragment>
                            }
                            {item.conversation.last_message.message !== undefined && item.conversation.last_message.message !== null && 
                                ReactHtmlParser(waContentToHtml(item.conversation.last_message.message))
                            }
                        </div>
            } else {
                let text = "";
                const { attachment } = item.conversation.last_message;
                if (attachment.content_type === "document") {
                    text = "Document";
                    return <div style={{display: 'flex'}}>{item.conversation.last_message.actor === "merchant" && 
                                <Fragment>
                                    {(item.conversation.conversation_source === "instagram" || item.conversation.conversation_source === "messenger" || item.conversation.last_message.delivered) ? null: <div style={{display: 'flex',marginTop: '5px'}}><Icon component={SentIcon} style={{marginRight: '5px'}} /></div>}
                                    {((item.conversation.conversation_source === "instagram" || item.conversation.conversation_source === "messenger" || item.conversation.last_message.delivered) && !item.conversation.last_message.read) ? <div><Icon component={DeliveredIcon} style={{marginRight: '5px',marginTop: '5px'}} /></div>: null}
                                    {item.conversation.last_message.read ? <Fragment><Icon component={SeenIcon} style={{marginTop: '5px',marginRight: '5px'}} /></Fragment> : null}
                                </Fragment>
                }<PaperClipOutlined style={{marginTop: '4px',marginRight: '3px'}} /> Document</div>
                } else if (attachment.content_type === 'audio') {
                    text = "Audio";
                    return <div style={{display: 'flex'}}>
                    {item.conversation.last_message.actor === "merchant" && 
                                <Fragment>
                                    {(item.conversation.conversation_source === "instagram" || item.conversation.conversation_source === "messenger" || item.conversation.last_message.delivered) ? null: <div style={{display: 'flex',marginTop: '5px'}}><Icon component={SentIcon} style={{marginRight: '5px'}} /></div>}
                                    {((item.conversation.conversation_source === "instagram" || item.conversation.conversation_source === "messenger" || item.conversation.last_message.delivered) && !item.conversation.last_message.read) ? <div><Icon component={DeliveredIcon} style={{marginRight: '5px',marginTop: '5px'}} /></div>: null}
                                    {item.conversation.last_message.read ? <Fragment><Icon component={SeenIcon} style={{marginTop: '5px',marginRight: '5px'}} /></Fragment> : null}
                                </Fragment>
                            }
                    <Audio style={{stroke: "#474747",marginTop: '3px',marginRight: '3px'}} /> Audio</div>
                } else if (attachment.content_type === "video") {
                    text = "Video";
                    return <div style={{display: 'flex'}}>
                    {item.conversation.last_message.actor === "merchant" && 
                                <Fragment>
                                    {(item.conversation.conversation_source === "instagram" || item.conversation.conversation_source === "messenger" || item.conversation.last_message.delivered) ? null: <div style={{display: 'flex',marginTop: '5px'}}><Icon component={SentIcon} style={{marginRight: '5px'}} /></div>}
                                    {((item.conversation.conversation_source === "instagram" || item.conversation.conversation_source === "messenger" || item.conversation.last_message.delivered) && !item.conversation.last_message.read) ? <div><Icon component={DeliveredIcon} style={{marginRight: '5px',marginTop: '5px'}} /></div>: null}
                                    {item.conversation.last_message.read ? <Fragment><Icon component={SeenIcon} style={{marginTop: '5px',marginRight: '5px'}} /></Fragment> : null}
                                </Fragment>
                            }
                    <Video style={{stroke: "#474747",marginRight: '6px', width: '16px',height: '16px', marginTop: '4px'}} /> {attachment.msg_type ? <span className="mention" style={{color: "#5B5B5B", fontSize: "16px",marginLeft: '50px'}}>{attachment.msg_type_name}</span> : " Video"}</div>
                } else if (attachment.content_type === "voice") {
                    text = "Voice";
                    return <div style={{display: 'flex'}}>
                    {item.conversation.last_message.actor === "merchant" && 
                                <Fragment>
                                    {(item.conversation.conversation_source === "instagram" || item.conversation.conversation_source === "messenger" || item.conversation.last_message.delivered) ? null: <div style={{display: 'flex',marginTop: '5px'}}><Icon component={SentIcon} style={{marginRight: '5px'}} /></div>}
                                    {((item.conversation.conversation_source === "instagram" || item.conversation.conversation_source === "messenger" || item.conversation.last_message.delivered) && !item.conversation.last_message.read) ? <div><Icon component={DeliveredIcon} style={{marginRight: '5px',marginTop: '5px'}} /></div>: null}
                                    {item.conversation.last_message.read ? <Fragment><Icon component={SeenIcon} style={{marginTop: '5px',marginRight: '5px'}} /></Fragment> : null}
                                </Fragment>
                            }
                    <Voice style={{stroke: "#474747"}} /> Voice</div>
                } else if (attachment.content_type === "image") {
                    text = "Image";
                    return <div style={{display: 'flex'}}>
                    {item.conversation.last_message.actor === "merchant" && 
                                <Fragment>
                                    {(item.conversation.conversation_source === "instagram" || item.conversation.conversation_source === "messenger" || item.conversation.last_message.delivered) ? null: <div style={{display: 'flex',marginTop: '5px'}}><Icon component={SentIcon} style={{marginRight: '5px'}} /></div>}
                                    {((item.conversation.conversation_source === "instagram" || item.conversation.conversation_source === "messenger" || item.conversation.last_message.delivered) && !item.conversation.last_message.read) ? <div><Icon component={DeliveredIcon} style={{marginRight: '5px',marginTop: '5px'}} /></div>: null}
                                    {item.conversation.last_message.read ? <Fragment><Icon component={SeenIcon} style={{marginTop: '5px',marginRight: '5px'}} /></Fragment> : null}
                                </Fragment>
                            }
                    <Image style={{marginTop: '5px',marginRight:'2px'}} /> {attachment.msg_type ? <span className="mention" style={{color: "#5B5B5B", fontSize: "16px"}}>{attachment.msg_type_name}</span> : " Image"}</div>
                }
            }
        }else{
            return item.conversation.last_message.subject;
        }
    };

    const onScroll = () => {
        if (listInnerRef.current && !loading) {
            const { scrollTop, scrollHeight, offsetHeight } = listInnerRef.current;
            if ((scrollTop + offsetHeight >= scrollHeight - 50) && !ticketsLoading) {
                onLoadMore();
                setTicketsLoading(true);
            }
        }
    };

    useEffect(()=>{
        if(datestamp && datestamp.data && datestamp.data.oldest_timestamp === "na"){
            setReachedBottom(true);
        }else{
            setReachedBottom(false);
        }
        setTicketsLoading(false);
    }, [datestamp])

    const goToTop = ()=>{
        ticketsRef.current[0].scrollIntoView({behavior: 'smooth'});
    }

    const onLoadMore = () => {
        const newPayload = payload;
        if(datestamp.data !==undefined){
            if(datestamp.data.oldest_timestamp !== "na"){
                dispatch(setPagination({
                    ...newPayload,
                    fetch_since: datestamp.data.oldest_timestamp,
                    page_size: 25,
                }));
            }
        }
    };

    const AddAccount = ({social})=>{
        return(
            <div style={{display: 'flex',flexDirection: 'column',alignItems: 'center',marginTop: '20px'}}>
                <p style={{color: '#000',fontSize: '18px',fontWeight: '400'}}>To receive / respond to your {social} messages</p> 
                <a href={`${process.env.REACT_APP_ORIGIN}/settings/#inboxSettings`} target="_blank">
                    <Button style={{background: "var(--color-primary)",color: "#fff",fontSize: "16px",height: "50px", marginTop: '10px'}}>Add Account</Button>
                </a>
            </div>
        )
    }

    const NameTitle= (item)=>{
        return(
            <div style={{display: 'flex',alignItems: 'center'}}>
                <span style={{maxWidth: '200px',overflow: 'hidden',textOverflow: 'ellipsis'}}>{item.conversation.full_name}</span>
                {activeConversation.ticket_id == item.ticket.id ? null :
                (!(item.conversation.conversation_read) || unreadId.includes(item.ticket.id)) ? <p className='unread-count' style={{minWidth: '50px',height: '24px',borderRadius: '20px',fontSize: '12px'}}>Unread</p> : 
                ((item.conversation.last_message.actor === "merchant") || item.conversation.last_message.read || activeConversation.ticket_id === item.ticket.id) ? null : <p className='unread-count'></p>
                }
            </div>
        )
    }

    const Options = [
        {
            key: 'unread',
            title: 'Mark as Unread',
            display: true,
        }
    ];
    
    const handleUnread = (e,id,item)=>{
        dispatch(markAsUnread({
            ticket_id: id
        }))
        setUnreadId([...unreadId, id]);
        NameTitle(item);
        e.stopPropagation();
    }

    const chatListMenu = (chat) => {
        return(
            <Menu className="header-menu-icon">
                {Options.map(item => {
                    if (item.display) {
                        return (
                            <Menu.Item key={item.key} value={item.key}>
                                <span className='icon' onClick={(e)=>handleUnread(e,chat.ticket.id,chat)} style={{fontSize: '16px',color: '#474747'}}>{item.title}</span>
                            </Menu.Item>
                        )
                    }
                })}
            </Menu>
        );
    };

    const handleSelect = (id,e,status, subscriber)=>{
        if(status !=="closed"){
            if(!subscriber || connections.is_store_admin){
                if(selectedArray.includes(id)){
                    const index = selectedArray.indexOf(id);
                    selectedArray.splice(index,1);
                    if(selectedArray.length > 0){
                        setSelectedArray(selectedArray);
                    }else{
                        setSelectedArray([]);
                    }
                }else{
                    e.stopPropagation();
                    e.preventDefault();
                    setSelectedArray([...selectedArray, id]);
                }
            }else{
                toast.error("Subscribed tickets cannot be transferred / closed", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                })
                e.stopPropagation();
                e.preventDefault();
            }
        }else{
            e.stopPropagation();
            e.preventDefault();
            toast.error("Ticket is closed", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
            })
        }
    }

    const handleRemoveUnread = (item)=>{
        item.conversation.conversation_read = true;
        const index = unreadId.indexOf(item.ticket.id);
        if(index > -1){
            unreadId.splice(index, 1);
        }
        // dispatch(setActiveConversation(item.ticket.id));
    }

    // Scroll to the Ticket in ticket list //

    // const ticketsRef = useRef([]);

    // const { id } = useParams();

    // const webhookStatus = useSelector(wsStatus);

    // useEffect(() => {
    //     ticketsRef.current = ticketsRef.current.slice(0, data.length);
    //     const ticketids = data.map(a=>a.ticket.id);
    //     if(id!==null && id !== undefined && ticketsRef && ticketsRef !==null && ticketsRef.current !==undefined && ticketsRef.current !==null){
    //         if(ticketids.indexOf(Number(id)) !== -1){
    //             ticketsRef.current[ticketids.indexOf(Number(id))].scrollIntoView({behavior: 'smooth'});
    //         }else{
    //             if(data !==null && data !== undefined && webhookStatus && ticketsRef.current[0]){
    //                 ticketsRef.current[ticketids.length -1].scrollIntoView({behavior: 'smooth'});
    //             }
    //         }
    //     }
    //  }, [data]);

    // ref={el => ticketsRef.current[i] = el} id={item.ticket.id}

    return (
        <StyledListWrap
            onScroll={() => onScroll()}
            ref={listInnerRef}
        >
            {loading ? (<List
                itemLayout="horizontal"
                dataSource={[...Array(12).keys()]}
                renderItem={item => (
                    <StyledListItem className="load-style">
                        <Skeleton avatar title={false} loading={loading} active>
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<Avatar className="initial-avatar"></Avatar>}
                                    title={''}
                                    description={''}
                                />
                                <StyledListMeta>
                                    <div></div>
                                </StyledListMeta>
                            </List.Item>
                        </Skeleton>
                    </StyledListItem>
                )}
                />) : (type=="instagram" && connections?.instagram?.enabled === false) ? 
                    <AddAccount social="instagram" />
                : (type=="messenger" && connections?.messenger?.enabled === false) ? 
                    <AddAccount social="messenger" />
                : (type=="whatsapp" && connections?.whatsapp?.enabled === false) ? 
                    <AddAccount social="whatsapp" />
                : (type=="email" && connections?.email?.enabled === false) ? 
                    <AddAccount social="email" />
                : (data.length == 0 || selectedArray===undefined) ? 
                    <div style={{textAlign: 'center', marginTop: "20px"}}>
                        <Empty />
                        <p style={{color: "#474747",fontSize: "26px",fontWeight: "700",margin: "0",marginTop:"10px"}}>Uh oh!</p>
                        <p style={{color: "#474747",fontSize: "16px",fontWeight: "300"}}>No conversations found.</p>
                    </div>
                :   <div>
                        <List
                            itemLayout="horizontal"
                            dataSource={data}
                            renderItem={(item, i) => (
                                <StyledListItem ref={el => ticketsRef.current[i] = el} id={item.ticket.id} onClick={()=>handleRemoveUnread(item)} selected={activeConversation.ticket_id == item.ticket.id}>
                                    {/*  <p style={{position: 'absolute',left: '-40px',zIndex:'99999',background: '#E12267',color: '#fff',rotate: '-90deg',width: '90px',top: '37px',textAlign: 'center',margin: '0',fontSize: '10px',overflow: 'hidden',textOverflow: 'ellipsis'}}>Company Name</p> */}
                                    <Link to={`/inbox/tickets/${item.ticket.id}`}>
                                        <List.Item>
                                            {item.conversation.conversation_source === "whatsapp" ? <StyledSource src={Whatsapp} /> : item.conversation.conversation_source === "instagram" ? <StyledSource src={Instagram} /> : item.conversation.conversation_source === "messenger" ? <StyledSource src={Facebook} /> : item.conversation.conversation_source === "email" ? <StyledSource src={EmailIcon} /> : ""  }
                                            <List.Item.Meta
                                                avatar={<div style={selectedArray.includes(item.ticket.id) ? {border: '3px solid #1d8dcc',borderRadius: "50%"} : {} }><Avatar style={selectedArray.includes(item.ticket.id) ? {padding: '8px',width: '48px',height: '48px'} : {}} className="initial-avatar">{getInitials(item.conversation.full_name)}</Avatar><div style={selectedArray.includes(item.ticket.id) ? {opacity: 1,left: '28px',top: '21px'} : {opacity: 0}} onClick={(e)=>handleSelect(item.ticket.id,e,item.ticket.status, item.ticket.is_subscriber)} className='overlay'><CheckIcon style={{display: 'flex',justifyContent: 'center',alignItems: 'center',width: '100%',marginTop: '5px',height: '30px', marginLeft: '4px'}} /></div></div>}
                                                title={NameTitle(item)}
                                                description={description(item)}
                                            />
                                            <StyledListMeta>
                                                <div style={{color: "#5B5B5B",fontWeight: "600",fontSize: "14px",marginRight: "10px"}}>#{item.ticket.id}</div>
                                                <div className="message-time">
                                                    <span>{moment(Date.parse(item.conversation.last_message.created_at)).fromNow()}</span>
                                                    <Dropdown className='unread-dropdown' overlay={chatListMenu(item)} placement="bottomRight">
                                                        <DownArrowIcon style={{stroke: '#1d8dcc'}} />
                                                    </Dropdown>
                                                </div>                                          
                                            </StyledListMeta>
                                        </List.Item>
                                    </Link>
                                </StyledListItem>
                            )}
                        />
                        {ticketsLoading && !reachedBottom && (<List
                            itemLayout="horizontal"
                            dataSource={[...Array(4).keys()]}
                            renderItem={item => (
                                <StyledListItem className="load-style">
                                    <Skeleton avatar title={false} loading={ticketsLoading} active>
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={<Avatar className="initial-avatar"></Avatar>}
                                                title={''}
                                                description={''}
                                            />
                                            <StyledListMeta>
                                                <div></div>
                                            </StyledListMeta>
                                        </List.Item>
                                    </Skeleton>
                                </StyledListItem>
                            )}
                        />)
                        }
                        {reachedBottom && <p style={{color: '#5b5b5b', padding: '10px 0px 20px', textAlign: 'center', fontWeight: '400'}}>You've reached end of tickets. <span className='gototop' style={{color: 'var(--color-primary)', cursor: 'pointer'}} onClick={()=>goToTop()}>Jump to top <CaretUpOutlined style={{color: 'var(--color-primary)'}} /></span></p>}
                    </div>
                }
        </StyledListWrap>
    );
}