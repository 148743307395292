import React, { useEffect, useState, useRef, Fragment } from "react";
import { Tooltip, Divider } from "antd";
import Countdown, { calcTimeDelta } from "react-countdown";
import { StyledInputContainer, StyledTextWrap, StyledReply } from "../styled";
import { nanoid } from "nanoid";
import { Button } from "antd";
import { ReactComponent as SendIcon } from "@assets/icons/message/send.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  sendMessage,
  replyToComment,
} from "@store-inbox/ConversationPanel/action";
import Emoji from "./Emoji";
import Attachment from "./Attachment";
import Message from "./Message";
import {
  conversationDataSelector,
  ownershipSelector,
} from "@store-inbox/ConversationPanel/selector";
import { ReactComponent as InitiateIcon } from "@assets/icons/main/initiatechat.svg";
import autosize from "autosize";
import { derivedConnectionsSelector } from "../../MainPanel/utils";
import { derivedAllTickets } from "../utils";
import { changeOwnership } from "@store-inbox/ConversationPanel/action";
import { toast } from "react-toastify";

function TextBox(props) {
  const {
    ticket: { owner_id, session_expiry_at, id, status },
    conversationId,
    conversation_source,
    ticket,
    setReplyPanel,
    input,
    setInput,
    replies,
    inputElement,
    commentReply,
    commentName,
    commentID,
    setCommentID,
    postID,
    hideReply,
    setCommentReply,
    commentIndex,
    setNewChat,
    setPhone,
    setCompose,
    ownerStatus,
    setOwnerStatus,
    chosenEmoji,
    setChosenEmoji,
    isOwner,
    setIsOwner,
    setSelectedArray,
    setTemplate,
    messages,
    setToID,
    displayText,
    variables,
    setDisableSubject,
  } = props;
  const { completed } = calcTimeDelta(new Date(session_expiry_at).getTime());

  const [display, setDisplay] = useState(false);
  const connections = useSelector(derivedConnectionsSelector);
  const allTickets = useSelector(derivedAllTickets);
  const ownership = useSelector(ownershipSelector);
  const [messagetobeSent, setMessagetobeSent] = useState(null);
  toast.configure();

  const dispatch = useDispatch();

  var liSelected;
  var replylist = useRef(null);
  var index = -1;

  useEffect(() => {
    if (inputElement) {
      if (conversation_source != "email") {
        inputElement.current.focus();
      }
      setInput("");
    }
  }, [inputElement, conversation_source]);

  const handleNewMessage = () => {
    setInput(inputElement.current.value);
    autosize(inputElement.current);
  };

  useEffect(() => {
    setReplyPanel(false);
  }, [id]);

  useEffect(() => {
    if (conversation_source === "whatsapp") {
      if (status === "closed") {
        setOwnerStatus(false);
      } else if (!completed) {
        setOwnerStatus(true);
      } else {
        setOwnerStatus(false);
      }
    } else if (status == "closed") {
      setOwnerStatus(false);
    } else {
      setOwnerStatus(true);
    }
  }, [id, completed, owner_id, connections]);

  useEffect(() => {
    if (input === "") {
      setCommentReply(false);
      setCommentID(null);
    }
  }, [input]);

  useEffect(() => {
    if (input.charAt(0) == "/") {
      setDisplay(true);
    } else {
      setDisplay(false);
    }
  }, [input]);

  const placeholder =
    status === "closed"
      ? "You cannot reply to this conversation. Ticket is closed"
      : status !== "unassigned"
      ? conversation_source === "instagram" ||
        conversation_source === "messenger"
        ? "Send a DM... Shift + Enter for next line"
        : conversation_source === "whatsapp"
        ? !completed
          ? "Shift + Enter for next line"
          : ""
        : "Click to compose email"
      : !completed && "Replying will assign and open the ticket for you";

  const handleEnterMessage = (e) => {
    if (!display) {
      if (e.key === "Enter" && e.shiftKey) {
        setInput(`${e.target.value}\n`);
        e.preventDefault();
      } else if (e.key === "Enter") {
        // if(status === "unassigned" || status === "assigned_to_bot"){
        //     dispatch(changeOwnership({
        //         ticket_ids: [Number(id)],
        //         transfer_to: connections.current_user_id,
        //     }));
        //     setMessagetobeSent(input);
        //     setInput('');
        //     e.preventDefault();
        // }
        // else{
        submitMessage(e);
        e.preventDefault();
        // }
        // if(payload.filters.ticket_status ==="assigned"){
        //     dispatch(setSearchFilters({
        //         ...payload,
        //         filters: {
        //             ...payload.filters,
        //             ticket_status: "open"
        //         },
        //         fetch_since: null,
        //         page_size: 25
        //     })
        //     )
        //     setPayload({
        //         ...payload,
        //         filters: {
        //             ...payload.filters,
        //             ticket_status: "open"
        //         },
        //         fetch_since: null,
        //         page_size: 25
        //     })
        //     setActiveKey("open");
        // }
      }
    } else {
      if (e.key === "Enter") {
        if (replylist.current !== null) {
          if (liSelected) {
            setInput(
              displayText(
                liSelected.firstChild.lastChild.textContent,
                variables
              )
            );
            setDisplay(false);
          }
        }
      }
      // e.preventDefault();
    }
  };

  useEffect(() => {
    if (chosenEmoji && conversation_source !== "email") {
      setInput(input + chosenEmoji.emoji);
      setChosenEmoji(null);
    }
  }, [chosenEmoji]);

  useEffect(() => {
    if (ownership) {
      setOwnerStatus(true);
      setIsOwner(true);
      if (messagetobeSent !== null && commentID === null) {
        const temp_id = nanoid();
        dispatch(
          sendMessage({
            message: messagetobeSent,
            conversation_id: Number(conversationId),
            temp_id,
            conversation_source: conversation_source,
            to: null,
            cc: null,
            bcc: null,
            ticket_id: id,
          })
        );
        setMessagetobeSent(null);
        toast.success(`#${id} has been assigned to you`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    }
  }, [messagetobeSent, ownership]);

  const submitMessage = (e) => {
    // if(status === "unassigned" || status === "assigned_to_bot"){
    //     dispatch(changeOwnership({
    //         ticket_ids: [Number(id)],
    //         transfer_to: connections.current_user_id,
    //     }));
    //     setMessagetobeSent(input);
    //     setInput('');
    if (input !== "" && commentID === null) {
      const temp_id = nanoid();
      dispatch(
        sendMessage({
          message: input,
          conversation_id: Number(conversationId),
          temp_id,
          conversation_source: conversation_source,
          to: null,
          cc: null,
          bcc: null,
          ticket_id: id,
        })
      );
      setInput("");
    } else if (input !== "" && commentID !== null) {
      const temp_id = nanoid();
      dispatch(
        replyToComment({
          comment: input,
          conversation_id: Number(conversationId),
          temp_id,
          conversation_source: conversation_source,
          comment_id: commentID,
          post_id: postID,
          hide_comment: hideReply,
          index: commentIndex,
        })
      );

      setCommentID(null);
      setInput("");
      setCommentReply(false);
    }
    e.preventDefault();
  };

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (conversation_source === "whatsapp") {
      if (completed) {
        // Render a completed state
        setOwnerStatus(false);
        return <span>Whatsapp session expired.</span>;
      } else {
        // Render a countdown
        return (
          <span>
            Whatsapp session expires in {hours} hr {minutes} mins {seconds} s
          </span>
        );
      }
    } else {
      return <span>Reply to the message</span>;
    }
  };

  const ToolTipContent = () => {
    return (
      <Countdown
        date={new Date(session_expiry_at).getTime() + 86400000}
        renderer={renderer}
      ></Countdown>
    );
  };

  const handleDown = (e) => {
    if (replylist.current !== null) {
      var len = replylist.current.getElementsByTagName("li").length - 1;
      index++;

      //down
      if (liSelected) {
        removeClass(liSelected, "selected");
        index =
          e.keyCode == 40 ? Math.min(index + 1, len) : Math.max(index - 1, 0);
        liSelected = replylist.current.getElementsByTagName("li")[index];
        if (liSelected) {
          addClass(liSelected, "selected");
          liSelected.scrollIntoView(true);
        }
      } else {
        index = 0;
        liSelected = replylist.current.getElementsByTagName("li")[0];
        if (liSelected) {
          addClass(liSelected, "selected");
          liSelected.scrollIntoView(true);
        }
      }
    }

    setInput(inputElement.current.value);
  };

  function removeClass(el, className) {
    if (el.classList) {
      el.classList.remove(className);
    } else {
      el.className = el.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
    }
  }

  function addClass(el, className) {
    if (el.classList) {
      el.classList.add(className);
    } else {
      el.className += " " + className;
    }
  }

  const handleCompose = () => {
    setCompose(true);
    setDisableSubject(true);
    let customerMessages = false;
    for (let i = 0; i < messages.length; i++) {
      if (
        messages[i].actor == "customer" &&
        messages[i].from &&
        messages[i].from.email
      ) {
        setToID([messages[i].from.email]);
        customerMessages = true;
        break;
      }
    }
    if (!customerMessages) {
      for (let i = 0; i < messages.length; i++) {
        if (messages[i].from && messages[i].from.email) {
          setToID([messages[i].to[0].email]);
          break;
        }
      }
    }
  };

  const handleInitiateTemplate = () => {
    setNewChat(true);
    setTemplate(true);
    setSelectedArray([id]);
  };

  return (
    <StyledInputContainer>
      {display && conversation_source !== "email" && (
        <StyledReply style={{ background: "#fff" }}>
          <ul ref={replylist} style={{ listStyle: "none", padding: "0" }}>
            {replies
              .filter((val) => {
                if (input == "/") {
                  return val;
                } else if (
                  val.shortcut.toLowerCase().includes(input.toLowerCase())
                ) {
                  return val;
                }
              })
              .map((reply, i) => {
                return (
                  <Fragment>
                    <li
                      className={i === 0 ? "selected" : ""}
                      style={{ padding: "5px 10px" }}
                      key={reply.id}
                      tabIndex={i}
                    >
                      <div
                        style={{ margin: "15px 0", cursor: "pointer" }}
                        onClick={() => {
                          setInput(displayText(reply.message, variables));
                          inputElement.current.focus();
                        }}
                      >
                        <p
                          style={{
                            margin: "0",
                            color: "#474747",
                            fontSize: "16px",
                            fontWeight: "700",
                          }}
                        >
                          {reply.shortcut}{" "}
                          <span
                            style={{
                              color: "#6F6F6F",
                              fontSize: "16px",
                              fontWeight: "200",
                            }}
                          >
                            - Quick Reply
                          </span>
                        </p>
                        <p
                          style={{
                            margin: "5px 0 0 0",
                            color: "#474747",
                            fontSize: "16px",
                          }}
                        >
                          {reply.message}
                        </p>
                      </div>
                      {i === 0 && (
                        <span
                          style={{
                            position: "absolute",
                            fontSize: "24px",
                            color: "#474747",
                            top: "10px",
                            right: "4px",
                            cursor: "pointer",
                          }}
                          onClick={() => setDisplay(false)}
                        >
                          x
                        </span>
                      )}
                    </li>
                    <Divider style={{ margin: "0" }} />
                  </Fragment>
                );
              })}
          </ul>
        </StyledReply>
      )}
      {commentReply && (
        <StyledReply
          style={{
            color: "#474747",
            backgroundColor: "#D2E8F5",
            padding: "10px 10px",
            overflow: "hidden",
          }}
        >
          <p style={{ margin: "0" }}>
            Commenting on{" "}
            <span style={{ fontWeight: "600" }}>{commentName}'s</span> comment
          </p>
        </StyledReply>
      )}
      <form>
        <StyledTextWrap>
          {conversation_source !== "email" && (
            <Emoji ownerStatus={ownerStatus} setChosenEmoji={setChosenEmoji} />
          )}
          <textarea
            readOnly={conversation_source !== "email" ? false : true}
            autoFocus
            style={{
              minHeight: "50px",
              maxHeight: "85px",
              resize: "none",
              lineHeight: "1",
              padding: "15px 6px 0px",
            }}
            onKeyDown={(e) => handleDown(e)}
            placeholder={placeholder}
            ref={inputElement}
            onClick={() => {
              if (conversation_source === "email") {
                handleCompose();
              }
            }}
            disabled={!ownerStatus}
            value={input}
            onChange={handleNewMessage}
            onKeyPress={handleEnterMessage}
            onKeyUp={handleNewMessage}
          />
          {conversation_source === "whatsapp" && completed && (
            <span
              style={{ color: "#33333399", position: "absolute", left: "40px" }}
            >
              Ticket has expired. Please send a template message by clicking{" "}
              <span
                style={{ color: "var(--color-primary)", cursor: "pointer" }}
                onClick={() => handleInitiateTemplate()}
              >
                here.
              </span>
            </span>
          )}
          {conversation_source !== "email" && (
            <Attachment
              ownerStatus={ownerStatus}
              ticket={ticket}
              conversationSource={conversation_source}
            />
          )}
          {conversation_source !== "email" && (
            <Message ownerStatus={ownerStatus} setReplyPanel={setReplyPanel} />
          )}
        </StyledTextWrap>
        {(status == "closed" && conversation_source !== "email") ||
        completed ? (
          <Tooltip title="Initiate Conversation" placement="top">
            <div
              className="send-button"
              onClick={() => handleInitiateTemplate()}
            >
              <Button
                className="icon-button"
                shape="square"
                icon={
                  <InitiateIcon style={{ stroke: "var(--color-primary)" }} />
                }
                type="link"
              />
            </div>
          </Tooltip>
        ) : (
          <Button
            className="send-button"
            icon={
              <Tooltip title={<ToolTipContent />}>
                <SendIcon style={{ stroke: "#474747" }} />
              </Tooltip>
            }
            disabled={!ownerStatus && conversation_source === "whatsapp"}
            onClick={submitMessage}
          />
        )}
      </form>
    </StyledInputContainer>
  );
}

export default TextBox;
