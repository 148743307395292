import axios from "axios";
import { setupCache } from "axios-cache-adapter";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
  clearOnStale: true,
});

const api = axios.create({
  adapter: cache.adapter,
});

var token;
if (
  localStorage.getItem("storeState") &&
  localStorage.getItem("storeState") !== null
) {
  var { auth } = JSON.parse(localStorage.getItem("storeState"));
  token = auth.token;
} else {
  const urlParams = new URLSearchParams(window.location.search);
  token = urlParams.get("token");
}

api.interceptors.request.use(function (config) {
  config.headers.Authorization = token;
  return config;
});

export function getAgents() {
  return api.get(`${process.env.REACT_APP_BE_HOST}/api/inbox/all_agents/`);
}

export function getOrders(id) {
  return api.get(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/conversations/${id}/orders/`
  );
}

export function getAbandoned(id) {
  return api.get(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/conversations/${id}/abandoned_checkouts/`
  );
}

export function getCustomer(id) {
  return api.get(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/conversations/${id}/customer_details/`
  );
}

export function getOnlineStatus() {
  return api.get(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/agent/online_status/`
  );
}

export function setOnlineStatus(status) {
  return api.post(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/agent/online_status/`,
    {
      agent_status: status,
    }
  );
}

export function uploadConversationFiles(payload) {
  const { conversation_id, files } = payload;
  return api.post(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/conversations/${conversation_id}/media/`,
    files,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
}

export function uploadEmailFiles(payload) {
  const { files } = payload.payload;
  return api.post(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/multiple/media-upload/`,
    files,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
}

export function getConnections() {
  return api.get(
    `${process.env.REACT_APP_BE_HOST}/api/connect/inbox/settings/`
  );
}

export function logout() {
  const {
    auth: { token },
  } = JSON.parse(localStorage.getItem("storeState"));
  api
    .post(`${process.env.REACT_APP_BE_HOST}/api/shopify/logout/`, {
      token: token,
    })
    .then((resp) => localStorage.removeItem("storeState"))
    .finally(
      (window.location.href = `${process.env.REACT_APP_ORIGIN}/users/login`)
    );
}

export function addQuickReply(payload) {
  const { shortcut, message } = payload;
  return api.post(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/canned-message/`,
    {
      shortcut: shortcut,
      message: message,
    }
  );
}

export function getAllQuickReplies() {
  return api.get(`${process.env.REACT_APP_BE_HOST}/api/inbox/canned-message/`);
}

export function deleteQuickReply(payload) {
  const { id } = payload;
  return api.post(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/canned-message/${id}/delete/`
  );
}

export function editQuickReply(payload) {
  const { id, shortcut, message } = payload;
  return api.post(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/canned-message/`,
    {
      id: id,
      shortcut: shortcut,
      message: message,
    }
  );
}

export function getTemplates() {
  return api.get(
    `${process.env.REACT_APP_BE_HOST}/api/connect/merchant-whatsapp-template/list/?sf=approved&t=initiate_chat&rt=false&mt=false`
  );
}

export function getCSV(filters) {
  return api.post(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/export/conversations/`,
    {
      filters: filters,
    }
  );
}

export function updateNotesAPI(ticket_id, notes, media) {
  return api.post(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/ticket/${ticket_id}/notes/`,
    {
      notes: notes,
      media: media,
    }
  );
}

export function getNotesAPI(ticket_id) {
  return axios.get(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/ticket/${ticket_id}/notes/`,
    {
      headers: {
        Authorization: token,
      },
    }
  );
}

export function getTicketsListApi(payload) {
  return axios.post(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/ticket/list/`,
    payload.payload,
    {
      headers: {
        Authorization: token,
      },
    }
  );
}

export function sendMessageApi(payload) {
  return axios.post(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/ticket/reply/`,
    payload.payload,
    {
      headers: {
        Authorization: token,
      },
    }
  );
}

export function getTicketContentApi(payload) {
  const { ticket_id } = payload;
  return axios.get(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/ticket/${ticket_id}/content-v2/`,
    {
      headers: {
        Authorization: token,
      },
    }
  );
}

export function transferOwnershipApi(payload) {
  return axios.post(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/ticket/transfer/owner-ship/`,
    payload.payload,
    {
      headers: {
        Authorization: token,
      },
    }
  );
}

export function setStatusApi(payload) {
  return axios.post(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/ticket/set-status/`,
    payload.payload,
    {
      headers: {
        Authorization: token,
      },
    }
  );
}

export function initiateConversation(payload) {
  return axios.post(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/initiate-whatsapp-template/`,
    payload.payload,
    {
      headers: {
        Authorization: token,
      },
    }
  );
}

export function msgsCounter(payload) {
  const refresh = payload.refresh;
  const ids =
    payload &&
    payload !== null &&
    payload.selectedAgents &&
    payload.selectedAgents !== null
      ? JSON.stringify(payload.selectedAgents)
      : "";
  return axios.get(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/ticket/counter/?agent_ids=${ids}&force_update=${refresh}`,
    {
      headers: {
        Authorization: token,
      },
    }
  );
}

export function setFollowUpApi(payload) {
  return axios.post(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/ticket/follow-up/`,
    payload.payload,
    {
      headers: {
        Authorization: token,
      },
    }
  );
}

export function errorLogsApi(payload) {
  return axios.post(
    "https://n8n.solidwebhook.com/webhook/aef58059-10f8-49a3-9f56-9a32b4f6d709",
    payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

export function getTagsApi() {
  return api.get(`${process.env.REACT_APP_BE_HOST}/api/inbox/ticket/tags/`);
}

export function createNewTicketApi(payload) {
  return axios.post(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/create-phone-ticket/`,
    payload,
    {
      headers: {
        Authorization: token,
      },
    }
  );
}

export function getAllTickets(payload) {
  return api.get(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/conversation/${payload}/tickets/`
  );
}

export function addTagsApi(payload) {
  return api.post(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/ticket/add-tags/`,
    payload.payload
  );
}

export function removeTagsApi(payload) {
  return api.post(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/ticket/remove-tags/`,
    payload.payload
  );
}

export function getFieldValues(id) {
  return axios.get(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/ticket/${id}/field-values/`,
    {
      headers: {
        Authorization: token,
      },
    }
  );
}

export function getLabelFields() {
  return api.get(`${process.env.REACT_APP_BE_HOST}/api/inbox/ticket/fields/`);
}

export function postLabelsApi(payload) {
  const { formDataToSend, ticketId } = payload;
  return api.post(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/ticket/${ticketId}/field-values/`,
    formDataToSend
  );
}

export function sendCommentApi(payload) {
  return api.post(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/ticket/comment/reply/`,
    payload.payload
  );
}

export function deleteCommentApi(payload) {
  return api.post(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/ticket/comment/delete/`,
    payload
  );
}

export function initiateMailApi(payload) {
  return api.post(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/initiate-email/`,
    payload
  );
}

export function getEmailGroups() {
  return api.get(`${process.env.REACT_APP_BE_HOST}/api/inbox/email/groups/`);
}

export function getLinkedTicketsapi(id) {
  return axios.get(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/link-ticket/${id}/`,
    {
      headers: {
        Authorization: token,
      },
    }
  );
}

export function postLinkedTicketsapi(payload) {
  const { formDataToSend, ticketId } = payload;
  return api.post(
    `${process.env.REACT_APP_BE_HOST}/api/inbox/link-ticket/${ticketId}/`,
    formDataToSend
  );
}
