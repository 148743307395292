export const SCOPE = "summary";
export const INIT = `${SCOPE}/INIT`;
export const GET_ORDERS = `${SCOPE}/GET_ORDERS`;
export const GET_ORDERS_SUCCESS = `${SCOPE}/GET_ORDERS_SUCCESS`;
export const GET_ORDERS_ERROR = `${SCOPE}/GET_ORDERS_ERROR`;
export const GET_ABANDONED_ORDERS = `${SCOPE}/GET_ABANDONED_ORDERS`;
export const GET_ABANDONED_ORDERS_SUCCESS = `${SCOPE}/GET_ABANDONED_ORDERS_SUCCESS`;
export const GET_ABANDONED_ORDERS_ERROR = `${SCOPE}/GET_ABANDONED_ORDERS_ERROR`;
export const GET_CUSTOMER_DETAILS = `${SCOPE}/GET_CUSTOMER_DETAILS`;
export const GET_CUSTOMER_DETAILS_SUCCESS = `${SCOPE}/GET_CUSTOMER_DETAILS_SUCCESS`;
export const GET_CUSTOMER_DETAILS_ERROR = `${SCOPE}/GET_CUSTOMER_DETAILS_ERROR`;
export const GET_CUSTOMER_TICKETS_SUCCESS = `${SCOPE}/GET_CUSTOMER_TICKETS_SUCCESS`;
export const GET_CUSTOMER_TICKETS_ERROR = `${SCOPE}/GET_CUSTOMER_TICKETS_ERROR`;
export const GET_CUSTOMER_LABELS = `${SCOPE}/GET_CUSTOMER_LABELS`;
export const GET_FIELD_VALUES_SUCCESS = `${SCOPE}/GET_FIELD_VALUES_SUCCESS`;
export const GET_FIELD_VALUES_ERROR = `${SCOPE}/GET_FIELD_VALUES_ERROR`;
export const POST_TICKET_LABELS = `${SCOPE}/POST_TICKET_LABELS`;
export const POST_TICKET_LABELS_SUCCESS = `${SCOPE}/POST_TICKET_LABELS_SUCCESS`;
export const POST_TICKET_LABELS_ERROR = `${SCOPE}/POST_TICKET_LABELS_ERROR`;
export const GET_LINKED_TICKETS = `${SCOPE}/GET_LINKED_TICKETS`;
export const GET_LINKED_TICKETS_SUCCESS = `${SCOPE}/GET_LINKED_TICKETS_SUCCESS`;
export const GET_LINKED_TICKETS_ERROR = `${SCOPE}/GET_LINKED_TICKETS_ERROR`;
export const POST_LINKED_TICKETS = `${SCOPE}/POST_LINKED_TICKETS`;
export const POST_LINKED_TICKETS_SUCCESS = `${SCOPE}/POST_LINKED_TICKETS_SUCCESS`;
export const POST_LINKED_TICKETS_ERROR = `${SCOPE}/POST_LINKED_TICKETS_ERROR`;
export const GET_NOTES = `${SCOPE}/GET_NOTES`;
export const GET_NOTES_SUCCESS = `${SCOPE}/GET_NOTES_SUCCESS`;
export const GET_NOTES_ERROR = `${SCOPE}/GET_NOTES_ERROR`;
