import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Select } from "antd";
import {
  labelsDataSelector,
  labelsLoaderSelector,
  labelsErrorSelector,
} from "@store-inbox/SummaryPanel/selector";
import { updateLabelValues } from "@store-inbox/SummaryPanel/action";
import { fieldsSelector } from "@store-inbox/MainPanel/selector";
import { currentActiveConversationSelector } from "@store-inbox/ConversationPanel/selector";
import { toast } from "react-toastify";

function Labels() {
  const { Option } = Select;

  const dispatch = useDispatch();

  const fieldValuesData = useSelector(labelsDataSelector);
  const fields = useSelector(fieldsSelector);
  const ticketId = useSelector(currentActiveConversationSelector);
  const [formDataUpdate, setFormDataUpdate] = useState([]);

  const labelsLoader = useSelector(labelsLoaderSelector);
  const labelsError = useSelector(labelsErrorSelector);
  const [labelsUpdating, setLabelsUpdating] = useState(false);

  const [fieldValues, setFieldValues] = useState({});

  useEffect(() => {
    // Initialize state with data values
    const initialFieldValues = {};
    if (fields && fields.response && fields.response.length > 0) {
      fields.response.forEach((field) => {
        initialFieldValues[field.field_name] =
          fieldValuesData[field.field_name] ||
          (field.field_type === "multiple_input" ? [] : "");
      });
      setFieldValues(initialFieldValues);
    }
  }, [fieldValuesData, fields]);

  const updateLabels = (e) => {
    e.preventDefault();

    const formDataToSend = formDataUpdate.reduce((accumulator, item) => {
      accumulator[item.field_name] = item.value;
      return accumulator;
    }, {});

    const values = {};
    const errors = {};

    fields.response.forEach((field) => {
      const fieldValue =
        formDataUpdate.find((f) => f.field_name === field.field_name)?.value ||
        fieldValuesData[field.field_name];
      values[field.field_name] = fieldValue;

      if (
        field.is_required &&
        (!fieldValue || (Array.isArray(fieldValue) && fieldValue.length === 0))
      ) {
        errors[field.field_name] = "Please fill out this field.";
      }
    });

    if (Object.keys(errors).length > 0) {
      toast.error(`Please fill out ${Object.keys(errors)[0]}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      return;
    }
    setLabelsUpdating(true);

    dispatch(updateLabelValues({ ticketId, formDataToSend }));
  };

  useEffect(() => {
    if (fields && fields !== null) {
      setFormDataUpdate(
        fields.response.reduce((acc, field) => {
          acc.push({
            field_name: field.field_name,
            value: fieldValuesData[field.field_name],
          });
          return acc;
        }, [])
      );
    }
  }, [fields, fieldValuesData]);

  useEffect(() => {
    if (labelsLoader) {
      document.getElementById("updateLabels").innerText = "Updating";
      document.getElementById("updateLabels").setAttribute("disabled", true);
    } else {
      if (fields && fields.response && fields.response.length > 0) {
        document.getElementById("updateLabels").innerText = "Update";
        document.getElementById("updateLabels").removeAttribute("disabled");
        if (labelsError == null) {
          if (labelsUpdating) {
            toast.success(`Custom fields have been updated`, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 6000,
              hideProgressBar: false,
              closeOnClick: true,
            });
          }
        } else {
          toast.error(
            labelsError.message || "Custom fields could not be updated",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 6000,
              hideProgressBar: false,
              closeOnClick: true,
            }
          );
        }
        setLabelsUpdating(false);
      }
    }
  }, [labelsLoader]);

  const handleChange = (field_name, value) => {
    setFormDataUpdate(
      formDataUpdate.map((item) =>
        item.field_name === field_name ? { ...item, value } : item
      )
    );
    setFieldValues((prevValues) => ({
      ...prevValues,
      [field_name]: value,
    }));
  };

  return (
    <div>
      {fields && fields.response && fields.response.length > 0 ? (
        <form onSubmit={updateLabels}>
          {fields.response.map((field) => {
            return (
              <div style={{ marginBottom: "1rem" }} key={field.id}>
                <p
                  style={{
                    fontSize: "16px",
                    textTransform: "capitalize",
                    marginBottom: "6px",
                  }}
                >
                  {field.field_name}
                  {field.is_required && " *"}
                </p>
                {!field.is_fixed_values ? (
                  field.field_type === "single_input" ? (
                    <input
                      required={field.is_required}
                      type="text"
                      placeholder={`Type your ${field.field_name} here...`}
                      id={`input_${field.id}`}
                      value={fieldValues[field.field_name]}
                      onChange={(e) =>
                        handleChange(field.field_name, e.target.value)
                      }
                      style={{
                        width: "100%",
                        border: "1px solid #ccc",
                        padding: "10px",
                      }}
                    />
                  ) : (
                    <Select
                      mode="tags"
                      required={field.is_required}
                      style={{
                        width: "100%",
                        fontSize: "14px",
                      }}
                      placeholder={`Select ${field.field_name}`}
                      rules={[
                        {
                          required: field.is_required,
                          message: "Please add!",
                        },
                      ]}
                      onChange={(value) =>
                        handleChange(field.field_name, value)
                      }
                      value={fieldValues[field.field_name] || []}
                      id={`select_${field.id}`}
                    >
                      {field.pre_defined_options.map((option) => {
                        return <Option key={option}> {option} </Option>;
                      })}
                    </Select>
                  )
                ) : (
                  <Select
                    {...(field.field_type === "multiple_input"
                      ? { mode: "multiple" }
                      : {})}
                    size="large"
                    style={{
                      width: "100%",
                      fontSize: "16px",
                    }}
                    rules={[
                      {
                        required: field.is_required,
                        message: "Please select!",
                      },
                    ]}
                    placeholder={`Select ${field.field_name}`}
                    value={fieldValues[field.field_name] || []}
                    onChange={(value) => handleChange(field.field_name, value)}
                    placement="bottomLeft"
                    id={`select_${field.id}`}
                    required={field.is_required}
                  >
                    {field.pre_defined_options.map((option) => {
                      return <Option key={option}> {option} </Option>;
                    })}
                  </Select>
                )}
              </div>
            );
          })}
          <Button
            type="primary"
            htmlType="submit"
            variant="contained"
            id="updateLabels"
          >
            Update
          </Button>
        </form>
      ) : (
        <div style={{ fontSize: "14px", color: "#333" }}>
          <p style={{ fontSize: "14px" }}>No custom fields available. </p>
          <p style={{ fontSize: "14px", marginTop: "10px" }}>
            Go to Settings &gt; Inbox &gt; Custom Fields{" "}
            <a
              href={`${process.env.REACT_APP_ORIGIN}/settings/#inboxSettings`}
              target="_blank"
              style={{ color: "var(--color-primary)" }}
            >
              (Click here)
            </a>{" "}
            to create new Ticket Fields
          </p>
        </div>
      )}
    </div>
  );
}

export default Labels;
